import { IonButton, IonButtons, IonCol, IonContent, IonDatetime, IonGrid, IonHeader, IonIcon, IonInput, IonItem, IonLabel, IonList, IonLoading, IonPage, IonPopover, IonRadio, IonRadioGroup, IonRow, IonText, IonTitle, IonToolbar, useIonAlert, useIonLoading, useIonToast } from "@ionic/react";
import { caretDown, chevronBackOutline, closeCircle, downloadOutline, play, repeatOutline, searchOutline, timeOutline } from "ionicons/icons";
import NavBar from "../../components/NavBar";
import { useHistory } from "react-router";
import { DropDownDevice } from "../../components/AppComponent";
import { useEffect, useState } from "react"; 
import axios from "axios";
import moment from "moment";
import { VehiclesSelect } from "./RealtimeMdvr";
import { getMdvrVehiclesBackup, setMdvrVehicle } from "../../store/menuSlice";
import { useDispatch, useSelector } from "react-redux";  
import { DeviceMdvr, FilePlayBack } from "./MdvrSchema"; 

import "../css/PlayBack.css"
import { Tabs, TabList, Tab, TabPanel } from "react-tabs"; 
import { t } from "i18next";
import { getStorage } from "../../actions";
import { SiginDetail } from "../schema";

// @ts-ignore
let swfobject = window.swfobject 
 
const PlayBack=()=>{
    const history = useHistory(); 
    const [toast,dimiss] = useIonToast();
    const [jsession,setJession] = useState('') 
    const [files , setFiles] = useState([])
    const [vehicleno , setVehicleNo] = useState("4sd000095") 
    const [vehicleplate , setVehiclePlate] = useState("") 
    const [mainScreen,setMainScreen] = useState<any>(null)
    const [date,setDate] = useState("")
    const [timestart,setTimeStart] = useState("") 
    const [timeend,setTimeEnd] = useState("")
    const [accordion,setAccordion] = useState("")
    const [chn,setChn] = useState(0)
    const [playurl,setPlayUrl] = useState(null)
    const [focusinout,setFocusInput] = useState(false)
    const vehicleBackup = useSelector(getMdvrVehiclesBackup)
    const dispatch = useDispatch() 
    const [playFiles,setPlayFiles] = useState([])
    const [playChannalFiles,setPlayChannalFiles] = useState([])
    const [deviceMdvr,setDeviceMdvr] = useState<DeviceMdvr|null>(null)
    const [chnDisplay,setChanDisplay] = useState("0,1,2,3")

    const [filelocation , setFileLocation]  = useState(1);
    const [recType , setRecType] = useState(-1)
    // const [loading , setLoading] = useState(false)
    const [ionLoading, dismissLoading] = useIonLoading();
    const [siginde,setSigninde] = useState<SiginDetail|null>(null)
    
    const getsignin=async()=>{
       let s = await getStorage("sigin")
       console.log("getsignin ",s)
       setSigninde(s)
    } 
    async function initPlayerExample(width:Number,height:Number) {
      //Video plug-in init param
      var params = {
        lang: "en" //"en", "zh-cn", "zh-tw"
        };
      //Init flash
      const SWF =  swfobject.embedSWF("player.swf", "cmsv6h5", "70%", "70vh", "11.0.0", null, null, params, null);
     
      console.log("SWF ",SWF)
      //undefined
        //@ts-ignore
       ttxVideoAll.init("cmsv6h5", "70%", "70vh", params, "auto");  //@ts-ignore
       const cmsvobj = swfobject.getObjectById("cmsv6h5")
       console.log("cmsvobj ",cmsvobj) 
 
    }
 
    
   useEffect(()=>{ 
      getsignin() 
      // const elWidth = Number(document.getElementById("flashExample")?.offsetWidth)
     
        const flashEl = document.getElementById("flashExample")
        var width 
        if(flashEl){   width = Number(flashEl?.offsetWidth)  }else{width = 600}
        initPlayerExample(400,400)
        loginmdvr() 
        setDate(moment().format("YYYY-MM-DDTHH:mm:ss"))
        setTimeStart(moment(date).format("YYYY-MM-DDT00:00:00"))
        setTimeEnd(moment(date).format("YYYY-MM-DDT23:59:59"))
     
    },[jsession,vehicleplate])

    const loginmdvr=async()=>{
        if(!jsession){
            let res = await axios.get("http://mdvr.attg.cc/StandardApiAction_login.action?account=Admin&password=@Andaman789",{}  ).then(async (res)=>{
                console.log("res.data.JSESSIONID ",res.data.JSESSIONID) 
                  
                return res.data }
            )
            console.log("res ",res)
            setJession(res?.jsession)  
        } else{ 
        }
    } 

 
    const Search =()=>{
      ionLoading({ 
        message: t("loading") ,
        duration: 3000,
        mode:"ios"
      })
      const day = moment(date).format("D")
      const month = moment(date).format("M")
      const year = moment(date).format("YYYY")
 
      let url = `http://mdvr.attg.cc/StandardApiAction_getVideoFileInfo.action?DevIDNO=${vehicleno}&LOC=${filelocation}&CHN=-1&YEAR=${year}&MON=${month}&DAY=${day}&RECTYPE=-1&FILEATTR=2&BEG=0&END=86399&ARM1=0&ARM2=0&RES=0&STREAM=0&STORE=0&jsession=${jsession}`
     console.log("url ",url)
      axios.get(url).then(res =>{ 
         dismissLoading()
         console.log("res ", res)
         let files = []
         if(res.data.result === 0 &&  res.data.files){
          files =  res.data.files
          setPlayFiles(res.data.files)  
          //@ts-ignore
          let group:any =  Object.groupBy( files , (e:any)=>e.chn) 
          // console.log("group ",group)
          var result:any = Object.keys(group).map((key) => group[key]);
          // console.log("result ",result)
          setPlayChannalFiles(result)
         }
        }).catch((err)=>{
          dismissLoading()
          console.log("err ",err)
        })
      //`http://mdvr.attg.cc/StandardApiAction_getVideoFileInfo.action?DevIDNO=${vehicleno}&LOC=2&CHN=${chn}&YEAR=${year}&MON=${month}&DAY=${day}&RECTYPE=-1&FILEATTR=2&BEG=0 &END=86399 &jsession=${jsession}&DownType=3&ARM1=0&ARM2=0&RES=0&STREAM:0&STORE=0&LABEL=${vehicleplate}`
      
      dismissLoading()
    }
 

  const filesinfo=async(f:FilePlayBack)=>{  
    //@ts-ignore
    console.log("filesinfo window.swfobject ",window.swfobject)
    //@ts-ignore
    swfobject = window.swfobject
    // initPlayerExample(400,400)
   console.log("swfobject  ",swfobject)
   
    let playUrl = f.PlaybackUrlWs+"&jsession="+jsession+"&PLAYBEG=0&PLAYEND="+f.end+"&chn="+f.chn
    
    console.log("swfobjectVideo ",swfobject.getObjectById("cmsv6h5"))
     //@ts-ignore 
     const swfobjectVideo = swfobject.getObjectById("cmsv6h5")
     console.log("swfobjectVideo ",swfobjectVideo)
      
     if(swfobjectVideo.Lu.length > 0){ 
      swfobjectVideo.stopVideo(0); 
      swfobjectVideo.reSetVideo(0); 
     }
     await  swfobjectVideo.startVodM(playUrl, '');  
    
  }
  
  const changeVehicleInput=(val:any)=>{ 
    console.log("inputsearch ",val)
    setVehiclePlate(val)
    if(val.length > 0){
     let filter = vehicleBackup.filter((e:any)=> e.nm.indexOf(val) > -1 )    
     console.log("filter ",filter)
     dispatch(setMdvrVehicle(filter)) 
    }else{ 
     dispatch(setMdvrVehicle(vehicleBackup)) 
    }
  }
  const dowloadFile=(urldowload:any)=>{
    window.open(urldowload+"playback"+vehicleplate+"&jsession="+jsession+"&dtp=1" ,"_blank")
  }
  const mdvrtime=(time:any,type:any)=>{
    let onehour = 3600

    let hourstr = (time/onehour ).toFixed(2)
    let timevid = hourstr.replace('.',':')
    let timevidarr = timevid.split(":")
 
    let sec =  0
    let min =  Number(timevidarr[1]) 
    
    if(min > 60 ){
      sec = min - 59 
      // min =  min-sec
    }
    min =  min-sec-sec
     
    return timevidarr[0]+":"+(min >10 ? min : "0"+min)+":"+(sec >10 ? sec: '0'+sec)
  }
 
    return(
    <IonPage>  
        <IonContent fullscreen color={"light"} >
        <IonHeader   className='ion-no-border'   > 
          <IonToolbar   mode='ios' className='ion-no-padding'>  
              <NavBar />  
              <IonButtons slot="start"  className='ion-hide-md-up  '  >
              <IonButton size='small' onClick={()=>{history.goBack()}}> <IonIcon icon={chevronBackOutline} />  <IonText >Back</IonText> </IonButton>
              </IonButtons>
              <IonTitle style={{fontWeight:"bold",fontSize:"1.3em",marginLeft:"1rem" }} >{'Reports'}</IonTitle>
          </IonToolbar>
        </IonHeader>
            <div className="mdvr-content"   >
            <IonGrid >
                <IonRow>
                    <IonCol sizeSm="12" sizeMd="4" sizeLg="3"  sizeXl="2.5" >
                      <div  className="playback-content" >
                        <IonLabel style={{fontWeight:"bold",display:"flex",justifyContent:"flex-start", alignItems:"center",marginBottom:"1.5rem",fontSize:"1.3em"}} > <IonIcon icon={repeatOutline} style={{fontSize:"1.5em"}} /> &nbsp; <IonText>Playback</IonText> </IonLabel>

                        {/* <DropDownDevice vehihle={vehicle} setVehicle={(e:any)=>{setVehicle(e) }}  movetop={false} />   */}
                        <div className="dropdown-input" id="dropdown-input" style={{ }} >
                          <IonRow>
                              <IonCol size="1" className="set-center" >
                                  <IonIcon  icon={searchOutline} style={{fontSize:"1em"}} />
                              </IonCol>
                              <IonCol size="9.5" className="ion-no-padding" style={{padding: "0 0 0 .5rem",}} > 
                                <IonInput placeholder="ทะเบียน" mode="ios"  className="dropdown-input-input"
                                value={vehicleplate}   onIonFocus={()=>{setFocusInput(true)}}
                                onIonChange={(e:any)=>{changeVehicleInput(e.detail.value)}}
                                onIonInput={(e:any)=>{changeVehicleInput(e.detail.value)}}
                                > 
                                </IonInput>
                              </IonCol>
                              <IonCol size="1.5" className="set-center ion-no-padding">
                                {focusinout ?  
                                  <IonButton  fill="clear" size="small"  color="medium"  onClick={()=>{setFocusInput(false)}} > 
                                    <IonIcon icon={closeCircle} />
                                  </IonButton>:
                                  <IonButton  fill="clear" size="small"  color="medium"    onClick={()=>{setFocusInput(true)}} > 
                                    <IonIcon icon={caretDown} />
                                  </IonButton>
                                }
                              </IonCol>
                          </IonRow>
                         {focusinout &&  <div style={{position:"absolute",width:"100%",bottom:"-30.4rem",left:'0px',height:"30rem",overflowY:"scroll",background:"#FFF",zIndex: 999,border:"1px solid #DDD",borderRadius:"10px"}} >
                            <VehiclesSelect 
                              accordion={accordion}  
                              changeAccordion={(e:any)=>{ setAccordion(e);setPlayFiles([]);setPlayChannalFiles([]); console.log("vehicleno ",e)}}  
                              setVehiclePlate={(e:any)=>{ setVehiclePlate(e);console.log("vehicleplate ",e)}} 
                              setVehicleNo={(e:any)=>{ setVehicleNo(e);console.log("vehicleNo ",e)}} 
                              setChannel={(e:any)=>{setChn(e) ;console.log("setChannel ",e)}}
                              setChanDisplay={(e:any)=>{ setChanDisplay(e);}}
                              chnDisplay={chnDisplay}
                              JSESSIONID={jsession} 
                              setDevice={(e:any)=>{setDeviceMdvr(e);console.log("setDevice ",e)}}
                            />
                          </div>}
                        </div> 
                        <IonDatetime 
                            value={date}  
                            onIonChange={(e:any)=>{setDate(e.detail.value!)}}
                            className="datetime" color={"primary"}
                            mode="ios" presentation="date"  
                        ></IonDatetime>
                        <div className="datetime" style={{ display:'flex',flexDirection:"row",justifyContent:"center",alignItems:"center"}} > 
                          <IonButton fill="clear" size="small" color={"dark"}>
                            <IonIcon icon={timeOutline} />&nbsp; <IonLabel>{moment(timestart).format("HH:mm")}</IonLabel>
                          </IonButton>
                          <span>-</span>
                          <IonButton fill="clear" size="small" color={"dark"}>
                            <IonIcon icon={timeOutline} /> &nbsp;<IonLabel>{moment(timeend).format("HH:mm")}</IonLabel>
                          </IonButton> 
                        </div>
                        <IonRow>
                            <IonCol size="6" >
                                <h6 style={{marginBottom:".5rem",fontSize:".9em"}} >File Location</h6> 
                                <IonRadioGroup value={filelocation} onIonChange={(e:any)=>{setFileLocation(e.detail.value)}} style={{fontSize:".85em",marginTop:"1rem"}} >
                                  <IonRadio value={1} labelPlacement="end">Terminal Device</IonRadio><br/>
                                  <IonRadio value={2} labelPlacement="end">Storage Device</IonRadio><br/>
                                  <IonRadio value={4}  labelPlacement="end">Dowload Server</IonRadio>
                                </IonRadioGroup> 
                            </IonCol>
                            <IonCol size="6" >
                                <h6 style={{marginBottom:".5rem",fontSize:".9em"}} >File Type</h6> 
                                <IonRadioGroup value={recType} onIonChange={(e)=>{setRecType(e.detail.value)}} style={{fontSize:".85em",marginTop:"1rem"}} >
                                  <IonRadio value={-1} labelPlacement="end">Record</IonRadio><br/>
                                  <IonRadio value={0}  labelPlacement="end">Audio</IonRadio><br/>
                                  <IonRadio value={1}  labelPlacement="end">Photo</IonRadio>
                                </IonRadioGroup> 
                            </IonCol>
                        </IonRow>

                        <button className="button-search"  onClick={()=>{Search()}}>
                            <IonLabel>Search</IonLabel>
                        </button>              
                    </div>
                    </IonCol>
                    <IonCol  > 
                        <IonRow>
                            <IonCol size="12" >   
                              <div  style={{width:'70vw',height:"90vh" }}  >
                                <div style={{width:"100%",height:"60%"}} className="set-center" >
                                   <div id="cmsv6h5"  style={{width:'100%',height:"100%",maxWidth: "1220px"}} ></div>
                                </div>
                                <div className="file-time" style={{height:"40%",overflowY:'scroll', paddingTop:".5rem"}} > 
                                <Tabs className={"mdvr-screen-tabs"} >
                                  <TabList>
                                    <Tab> File </Tab>
                                    <Tab> Time </Tab>
                                  </TabList>
                                   <TabPanel>   
                                    <IonGrid className="table-files" >
                                      <IonRow  className="row-header" style={{margin:"1rem 0 1rem"}}>
                                        <IonCol size="1.2" className="cell-header" >  <IonLabel>Operate</IonLabel>  </IonCol>
                                        <IonCol size="1"   className="cell-header">  <IonLabel>Index</IonLabel>    </IonCol>
                                        <IonCol size="1.2" className="cell-header">  <IonLabel>Time</IonLabel>     </IonCol>
                                        <IonCol size="1"   className="cell-header">  <IonLabel>Type</IonLabel>     </IonCol>
                                        <IonCol size="1.3" className="cell-header">  <IonLabel>Plate No.</IonLabel></IonCol>
                                        <IonCol size="1"   className="cell-header">  <IonLabel>Channel</IonLabel>  </IonCol>
                                        
                                        <IonCol size="1.3" className="cell-header">  <IonLabel>File Location</IonLabel></IonCol>
                                        <IonCol size="1.3" className="cell-header">  <IonLabel>Size (MB)</IonLabel>  </IonCol>
                                        <IonCol size="1.9" className="cell-header">  <IonLabel>File Path</IonLabel>  </IonCol>
                                      </IonRow>
                                    {
                                      playFiles.map((file:FilePlayBack|any,index:any)=>
                                        <IonRow key={index} >
                                          <IonCol size="1.2" style={{ fontSize:".9em"}}>  
                                            <IonButton size="small" fill="clear"
                                              onClick={()=>{ dowloadFile(file?.DownUrl)}} 
                                            >
                                              <IonIcon icon={downloadOutline} />
                                            </IonButton>
                                            <IonButton size="small" fill="clear"
                                            onClick={()=>{console.log("file ",file);filesinfo(file) ;}}
                                            >
                                              <IonIcon icon={play} />
                                            </IonButton>
                                          
                                          </IonCol> 
                                          <IonCol size="1"   style={{ fontSize:".9em"}}>  <IonLabel>{index+1}</IonLabel>    </IonCol>
                                          <IonCol size="1.2" style={{ fontSize:".9em"}}>  <IonLabel>{file.day}/{file.mon}/{file.year} {mdvrtime(file.beg ,'beg')}-{mdvrtime(file.end,'end')}</IonLabel>     </IonCol>
                                          <IonCol size="1"   style={{ fontSize:".9em"}}>  <IonLabel>{file.type}</IonLabel>     </IonCol>
                                          <IonCol size="1.3" style={{ fontSize:".9em"}}>  <IonLabel>{file.devIdno}</IonLabel></IonCol>
                                          <IonCol size="1"   style={{ fontSize:".9em"}}>  <IonLabel>{file.chn}</IonLabel>  </IonCol>
                                          <IonCol size="1.3" style={{ fontSize:".9em"}}>  <IonLabel>Terminal Device</IonLabel></IonCol>
                                          <IonCol size="1.3" style={{ fontSize:".9em"}}>  <IonLabel>{file.len}</IonLabel>  </IonCol>
                                          <IonCol size="1.9" style={{ fontSize:".9em"}}>  <IonLabel> {file.file} </IonLabel>  </IonCol>
                                        </IonRow>
                                      )
                                    }  
                                    </IonGrid>
                                  </TabPanel>
                                  <TabPanel>  
                                    {playChannalFiles.length > 0 &&  <TimeLinePlayBack  
                                    files={playChannalFiles}  datet={date} 
                                    selected={(file:any)=>{console.log("selected file ",file); filesinfo(file)}} 
                                    />}
                                   
                                    
                                  </TabPanel> 
                                </Tabs>
                                
                                </div> 
                            </div> 
                          </IonCol>
                          
                        </IonRow>
                     
                    </IonCol>
                </IonRow>
            </IonGrid>
            </div> 
        </IonContent>
    </IonPage>
    )
}
export default PlayBack;

 

const TimeLinePlayBack=({files,date,selected}:any)=>{
  var apexchrt :any = null ;
  let seriess:any[] = []
  let options:any ={
    chart: {
    height: 300,
    type: 'rangeBar',
    events: {
      dataPointSelection: (event:any, chartContext:any, opts:any) => {
        console.log(chartContext, opts);
        let serieSelect = seriess[opts.seriesIndex]
        let file = serieSelect?.data[opts?.dataPointIndex].file
        console.log("file ", file);
        //f.PlaybackUrlWs+"&jsession="+jsession+"&PLAYBEG=0&PLAYEND="+f.end+"&chn="+f.chn
        return selected(file)
      }
    }
  },
  plotOptions: {
    bar: {
      horizontal: true,
      barHeight: '50%',
      rangeBarGroupRows: true
    }
  },
  colors: [
    "#008FFB", "#00E396", "#FEB019", "#FF4560", "#775DD0",
    "#3F51B5", "#546E7A", "#D4526E", "#8D5B4C", "#F86624",
    "#D7263D", "#1B998B", "#2E294E", "#F46036", "#E2C044"
  ],
  fill: {
    type: 'solid'
  },
  xaxis: {
    type: 'datetime'
  },
  legend: {
    show: false ,
    position: 'right'
  },
  tooltip:{ x: { format: 'dd/MM HH:mm:ss' }, },
  } 

  useEffect(  ()=>{
    console.log("files ",files)
    console.log("date ",date)
    
    const day = moment(date).format("DD")
    const month = moment(date).format("MM")
    const year = moment(date).format("YYYY")
    //files
    let chn1 = files[0]
    let chn2 = files[1]
    let chn3 = files[2]
    let chn4 = files[4]
 
    let dataCh1 = chn1.map((e:FilePlayBack)=>{ 
      let beg = `${year}-${month}-${day} ${mdvrtime(e.beg ,'')}`
      let end = `${year}-${month}-${day} ${mdvrtime(e.end ,'')}`
      return {
        file: e,
        url: e.PlaybackUrlWs ,
        x: "Ch1",
        y: [
          new Date(moment(beg).add(7,'hour').format()).getTime(),
          new Date(moment(end).add(7,'hour').format()).getTime()
        ]
      } 
    })

    let dataCh2 = chn2?.map((e:FilePlayBack)=>{ 
      let beg = `${year}-${month}-${day} ${mdvrtime(e.beg ,'')}`
      let end = `${year}-${month}-${day} ${mdvrtime(e.end ,'')}`
      return {
        url: e.PlaybackUrlWs ,
        file: e,
        x: "Ch2",
        y: [
          new Date(moment(beg).add(7,'hour').format()).getTime(),
          new Date(moment(end).add(7,'hour').format()).getTime()
        ]
      } 
    })

    let dataCh3 = chn3?.map((e:FilePlayBack)=>{ 
      let beg = `${year}-${month}-${day} ${mdvrtime(e.beg ,'')}`
      let end = `${year}-${month}-${day} ${mdvrtime(e.end ,'')}`
      return {
        file: e,
        url: e.PlaybackUrlWs ,
        x: "Ch3",
        y: [
          new Date(moment(beg).add(7,'hour').format()).getTime(),
          new Date(moment(end).add(7,'hour').format()).getTime()
        ]
      } 
    })
    let dataCh4 = chn4?.map((e:FilePlayBack)=>{ 
      let beg = `${year}-${month}-${day} ${mdvrtime(e.beg ,'')}`
      let end = `${year}-${month}-${day} ${mdvrtime(e.end ,'')}`
      return {
        file: e,
        url: e.PlaybackUrlWs ,
        x: "Ch4",
        y: [
          new Date(moment(beg).add(7,'hour').format()).getTime(),
          new Date(moment(end).add(7,'hour').format()).getTime()
        ]
      } 
    })
    let serieCh1 = {
      name: 'Ch 1 ',
      data:  dataCh1
    }
    let serieCh2 = {
      name: 'Ch 2 ',
      data:  dataCh2
    }
    let serieCh3 = {
      name: 'Ch 3 ',
      data:  dataCh3
    }
    let serieCh4 = {
      name: 'Ch 4 ',
      data:  dataCh4
    }

    console.log("serieCh1 ",serieCh1)
    console.log("serieCh2 ",serieCh2)
    console.log("serieCh3 ",serieCh3)
    console.log("serieCh4 ",serieCh4)

    let series = []
    if(serieCh1.data !== undefined){ series.push(serieCh1)}
    if(serieCh2.data !== undefined){ series.push(serieCh2)}
    if(serieCh3.data !== undefined){ series.push(serieCh3)}
    if(serieCh4.data !== undefined){ series.push(serieCh4)}
    console.log("options ",options)

    seriess  = series   
    options = {...options , ...{series:seriess}}
    const chartEl = document.getElementById("chart") 

    if(!apexchrt){ 
      apexchrt = new ApexCharts(document.querySelector("#chart"), options);
      apexchrt.render();
    }
    console.log("seriess ",seriess)
  },[files])


  const mdvrtime=(time:any,type:any)=>{
    let onehour = 3600

    let hourstr = (time/onehour ).toFixed(2)
    let timevid = hourstr.replace('.',':')
    let timevidarr = timevid.split(":")
 
    let sec =  0
    let min =  Number(timevidarr[1])  
    if(min > 60 ){
      sec = min - 59 
      // min =  min-sec
    }
    min =  min-sec-sec
    
     
    
    return timevidarr[0]+":"+(min >10 ? min : "0"+min)+":"+(sec >10 ? sec: '0'+sec)
  }
 

  return(<div>
    <div id="chart" className="playfile-timeline" style={{width:"100%"}}> 
    </div>
  </div>)
}
import { IonButton, IonCol, IonContent, IonGrid, IonIcon, IonLabel, IonRow } from "@ionic/react"
import { useEffect } from "react"
import ReactApexChart from "react-apexcharts";  
import { TableFuelUsage, TableOverview } from "./ReportComponent/ReportTable";
import { printOutline } from "ionicons/icons";
import { pdfMake } from "../../lib/pdfMake";
import "./Reports.css" 
import * as Excel from "exceljs";
import FileSaver from "file-saver";

const borderCells:any = {
    top: {style:'thin', color: {argb:'000000'}},
    left: {style:'thin', color: {argb:'000000'}},
    bottom: {style:'thin', color: {argb:'000000'}},
    right: {style:'thin', color: {argb:'000000'}}
  }


const RepFuelUsaged=({ table ,detail, overview , chart  ,children }:any)=>{
    let width = 350
    let options:any = {
        chart: 	 { id: "line-fuel"	, type: 'line', background: 'transparent'},
        xaxis: 	 { type: 'datetime' , categories:[''] , },
        fill: 	 { type:'solid'	}   ,
        stroke:  { curve: 'smooth'	, width: 1 },
        markers: { size: 1 , strokeWidth: 0, },
        tooltip: { shared: false,  x: {
            show: true,
            format: 'dd/MM HH:mm',
            formatter: undefined,
        }, },
        colors: ['#fce303', '#16b526','#134985'],
        yaxis: {
            labels: {
                formatter: function (value:any) {
                  return value.toFixed(0);
                }
            },
        }, 
    }

    const printExcel=async ()=>{
        try{  
            console.log("detail ",detail);
            var options = {
                filename: './streamed-workbook.xlsx',
                useStyles: true,
                useSharedStrings: true
            }; //@ts-ignore 
            let workbook = new Excel.Workbook(options);
            var ws = workbook.addWorksheet( detail?.reportName, {properties:{tabColor:{argb:'FFF'}}});
            let chart = ApexCharts.getChartByID("line-fuel") 
            const myBase64Image =  await chart?.dataURI().then((e:any) =>{ 
                return e?.imgURI
            }) 
            var chartimg = workbook.addImage({ 
                base64: myBase64Image,
                extension: 'png',
            });
            for(let i=0 ;i<=500; i++){
                ws.getRow(1).font = {  size: 18, name:'Angsana New' }; 
            }  
            
            ws.columns =[{width:10},{width: 15},{width: 20},{width: 50},{width: 15},{width: 15},{width: 15},{width: 15}]
            ws.mergeCells("A1:G1");

            ws.getCell("A1").value = detail?.reportName 
            ws.getRow(1).font = {  size: 20,  bold: true   }; 
            ws.getRow(1).alignment = {horizontal:'center' , vertical:'middle' }

            ws.addRow([''])
            ws.addImage(chartimg, 'B2:F20'); 
            ws.mergeCells("B10:C10");

            ws.getCell("A22").value = "Overview" 
            ws.getRow(22).font = {  size: 16,  bold: true }; 

            ws.getCell("A23").value = "Distance"  
            ws.addRow(['Distance', detail?.distance.toString()])  //r 23 
            ws.mergeCells("C23:D23");  

            ws.getCell("A24").value = "Fuel Usaged"  
            ws.addRow(['Fuel Usaged', detail?.fuelUsaged.toString()]) 
            ws.mergeCells("C24:D24");  

            ws.getCell("A25").value = "Expense"  
            ws.addRow(['Expense', detail?.expenses.toString()]) //r 25 
            ws.mergeCells("C25:D25");  

            ws.addRow(['']) 
         

            const header = ["Status" , "Fuel" , "Duration" , "Address"  ,  "Latitue" , "Longitude" , "Distance"]
            ws.addRow(header)
            const rowheader = ws.getRow(28); 
            rowheader.eachCell({includeEmpty: true}, ((cell:any) => {
                cell.alignment = { vertical: 'middle', horizontal: 'center', wrapText: true }; 
                cell.font = { bold: true }
                cell.border = borderCells
            }));
 
            let allrows:any =  await detail?.report.map(async(rep:any,index:any)=> { 
                const reportrows:any = await rep?.summary.map((summ:any)=>{ 
                    let duration  = `${rep?.date} ${summ.startTimeStr}-${summ.endTimeStr}` 
                    const row = [
                        summ?.status === 7 ? "เคลื่อนที่"  : "จอดติดเครื่อง" ,
                        summ?.fuel.toFixed(2) ,
                        duration ,
                        summ?.events[0].address ,
                        summ?.events[0].latitude ,
                        summ?.events[0].longitude,
                        summ?.distance.toFixed(2)
                    ] 
                    let rowadded = ws.addRow(row)
                    rowadded.height =   rowadded.height*2 ;
                    rowadded.eachCell({includeEmpty: true}, ((cell:any,colnum:any) => {  
                        cell.border = borderCells
                        cell.alignment = {wrapText: true}
                    }));
                    return row
                })
               return reportrows 
            })
   
            let datetime =  detail?.start+"-"+detail?.end //moment(detail?.start).format("DD/MM/YYYY")
            let fileName=`ReportFuelUsaged-${datetime}.xlsx`; 

            workbook.xlsx.writeBuffer() 
            .then(function(buffer:any) { 
                const data: Blob = new Blob([buffer], {type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'});
                FileSaver.saveAs(data, fileName);
            }); 

        }catch(err){
            console.log("err ",err) 
        } 
    }

    const printReport=async ()=>{
        let chart = ApexCharts.getChartByID("line-fuel") 
        let bodyTable: any[] = []
        const overivewtable =await overview.map((e:any)=>{
            return [ e[0].label , e[1].label ]
        }) 
        const myBase64Image =  await chart?.dataURI().then((e:any) =>{ 
            return e?.imgURI
        })  
        const headers = table.columns.map((el:any)=>{
            return  {text: el.label  , alignment:'center', bold:true  } 
        })
        table.rowData.map((rows:any)=>{  
           let row =  rows.cols.map((cols:any)=>{ 
               return  {text:  cols.label  , alignment:'center' }  
           })
           bodyTable.push(row)
        }) 
        let headwidths = headers.map((e:any)=>{return  e =="address" ? 300: "auto"})
        bodyTable.unshift(headers)
        console.log("bodyTable ",bodyTable ," headwidths ",headwidths ," overivewtable ",overivewtable)

        pdfMake.createPdf({ 
            pageOrientation: 'landscape',
            watermark: { text: 'Andaman Tracking', color: '#FD5F06', opacity: 0.1, bold: true, italics: false },
            images: {
                chartimg: myBase64Image
            },
            content: [  
                {text: detail.reportName , fontSize: 24 , bold:true  , alignment:'center', marginBottom: 15},
                { image:"chartimg" , width: 500, marginBottom: 15 ,alignment:'center', } ,
                {text: "ภาพรวม" , fontSize: 16 , marginBottom: 5},
                { table: { 
                    headerRows: 1,
                    widths: [ 70 , 200  ], 
                    body: overivewtable  ,
                  }, 
                  marginBottom: 10
                }, 
                {   
                    layout: 'lightHorizontalLines',
                    marginTop: 10 ,
                    table:{
                      dontBreakRows:true,
                      headerRows: 1,
                      widths: headwidths,
                      body: bodyTable  
                    }
                  } 
            ] ,
            header: function (currentPage, pageCount) {
                return {
                    columns: [
                        { 
                            text: detail.reportName,
                            fontSize: 10 ,
                            marginLeft: 16,
                            margin: [20, 20, 50, 20] 
                        } ,
                        { text: "Page " + currentPage  + ' of ' + pageCount, alignment: 'right', style: 'normalText', margin: [0, 20, 50, 0] },
                    ]
                }
            },
            footer: {
                columns: [
                    { 
                        text:"บริษัท อันดามันแทร็คกิ้ง จำกัด | Andaman Tracking .Co.Ltd  @Generate on  tracking.andamantracking.com" ,
                        fontSize: 9 ,
                        marginLeft: 15
                    } 
                ]
            },
            defaultStyle: {
                font: "THSarabunNew",
            },
            }).open()
    }


    useEffect(()=>{
        console.log("table ",table);
        console.log("RepFuelUsaged detail ",detail )
        if(chart ){
            options = chart?.option  
            let mainrep = document.getElementById("main-report-content")
            if(mainrep){
                width = mainrep?.clientWidth
                let chart = ApexCharts.getChartByID("line-fuel") 
                
                console.log(" mainrep?.clientWidth ",mainrep?.clientWidth)
            }
        } 
    },[])

    return(<div className="ion-padding" >  
    {children}
        <div  style={{position:"relative", top:"1rem", right:"1rem",display:"flex",flexDirection:"row",alignItems:"center"}} > 
            <IonButton  
                onClick={()=>{printReport()}}
                mode="ios" fill="clear" 
            >
                <IonLabel  style={{fontSize:".8em"}}>Print</IonLabel>  &nbsp;
                <IonIcon icon={printOutline}  /> 
            </IonButton>
            <IonButton onClick={()=>{printExcel()}}     mode="ios" fill="clear"   >
                <IonLabel style={{color:"#12753D", fontSize:".8em"}}> Excel </IonLabel> &nbsp;
                <img src="../assets/icon/excel.svg" style={{width:"1.7rem"}} />
            </IonButton>
        </div>
        {
            chart.length > 0 && <ReactApexChart options={options} series={chart?.series} width={"100%"} height={250} type="line" /> 
        }
        <IonGrid>
            <IonRow>
                <IonCol  size="12"  >  <IonLabel> ภาพรวม</IonLabel>  </IonCol>
                <IonCol   size="6"  > 
                { overview && <TableOverview overview={overview} /> }
                </IonCol>
            </IonRow>
            <IonRow>
                <IonCol  size="12" >
                    <TableFuelUsage  headers={table?.columns}  rowsData={table.rowData}  />
                </IonCol>
            </IonRow>
        </IonGrid>
       
</div>
    )
}
export default RepFuelUsaged
import * as Moment from "moment"
import { getBehaviorDevice, historyRoute, repCarsDailyUse, repClosOpenPTO, repDistanceDaily, repFuelUsaged, repGasolone, repGasoloneSpeed, repInOutGeofense, repMinuteDaily, repOverSpeed, repOverTime, repPieChartDaily, repRangDistance, repStatusColumn, repStatusDaily, repTemperature, report1, reportDeviceInOutGeozoe, reportIgnition, reportInOutGeozoe, reportOutInGeozoe, summarypiechart } from "../../../actions" 
import { setReport0014, convertMsToTime, setReport0021, setReport0026, setReport0031, setReport0033, setReport0035, setReport0023, setReport0051, setReport0052, setReport0053, setReport0041, setReport0061, setReport0016, setReport0042, setReport0043, setReport0056 } from "../ReportUtils"
import { extendMoment } from "moment-range";
const moment = extendMoment(Moment);
moment.locale("th")

const tableNoData = {
    header: [],
    rowData:[]
}
export const reportbyid=async({report,datestart,dateend,vehihle,vehihcles,more}:any)=>{
    let reportContent:any
    let success = false;
    if(report.id === "0011"){
        const res:any = report1({})
        console.log("res searchReport ",res)
        reportContent = {
          success: success,
          chart: res.chart ,
          table: res.table ,
          detail:{
            reportName: report?.name,
            dateStart: datestart ,
            dateEnd: dateend ,
            device:{
              name: vehihle.label,
              device_id: vehihle.value 
            } 
          } 
        }
         
      }else if(report.id === "0014"){
        let res = await getBehaviorDevice({
          deviceId: vehihle?.device_id,
          start: new Date(datestart).getTime() ,
          end: new Date(dateend).getTime()
        })
       success = true
       const rep = await setReport0014(res)
       reportContent = { 
          success: success,
          table:rep.table, 
          pdf: rep.pdfCols ,
          detail:{
            name: vehihle?.label ,
            start: moment(datestart).format("HH:mm DD MMM YYYY") ,
            end:  moment(dateend).format("HH:mm DD MMM YYYY"), 
            reportName: report?.name,
          }
        }
        
      }else if(report.id === "0015"){ 
        let deviceId =  vehihle.device_id
        let date = moment(datestart).format("DD/MM/YYYY")
        let dateEnd = moment(dateend).format("DD/MM/YYYY")
        let start = moment(datestart).format("YYYY-MM-DD 00:00:00")   
        let end = moment(dateend).format("YYYY-MM-DD HH:mm:ss") 
        let result = await historyRoute({deviceId,date,dateEnd,start,end })
        console.log("result ",result)
        result?.list.length >0 ? success=true : success=false

        let polyline =await  result?.list.map((event:any)=> {
          return [ event.latitude , event.longitude ]
         }) 
        reportContent  = { ...{
            polyline : polyline ,
            detail: {
              name: vehihle.label ,
              reportName:report?.name,
              start: datestart ,
              end: dateend
            } ,
            success:success,
          },
        ...{ overview: result} }
         console.log("reportContent ",reportContent) 
  
      }else if(report.id === "0016"){ 
        console.log("more ",more)
        let deviceId =  vehihle.device_id 
        const rangestart = new Date(datestart);
        const rangeend   = new Date(dateend); 
        const range1 = moment.range(rangestart, rangeend); 
        const rangeday = range1.diff('days') 
        let formbody = []
      
        let datebegin =moment(datestart).format()
        for(let i=0; i<=rangeday;i++){
            formbody.push({
                deviceId: deviceId ,
                date:  moment(datebegin).format('DD/MM/YYYY') ,
                start: new Date(moment(datebegin).format("YYYY-MM-DD 00:00:00")).getTime(),
                end: new Date(moment(datebegin).format("YYYY-MM-DD 23:59:59")).getTime(),
            }) 
            datebegin = moment(datebegin).add(1,'day').format()
        } 
        console.log("formbody ",formbody)

        let result = await repFuelUsaged({  deviceId: deviceId ,body: formbody ,fuelprice: more?.fuelprice ,rate : more?.ratefuel} )  
        
        console.log("repFuelUsaged result",result)
        let table= await setReport0016(result)
        console.log("setReport0016 table",table)

        result ? success=true : success=false 
        let list = result.chart
 
        let seriesupdate:any = []
        let yellowArr:any	=[] 
        let greenArr:any	=[]

        let seriesYellow = { name: 'idle',type: 'scatter',data: [[0,'1']] } 
        let seriesGreen = {name: 'move',type: 'scatter',data: [[0,'1']] }
        let seriesLine = {name: 'All',type: 'area',data: [[ 0, '1']]}
        seriesYellow.data =[] 
        seriesGreen.data =[]
        seriesLine.data =[]

        yellowArr =  list.filter((list:any)=>  list.color === 'yellow' || list.color === "#ffd433") 
        greenArr =  list.filter((list:any)=>  list.color === 'green')
        let optionupdate = {
          chart: 	 { id: "line-fuel"	, type: 'line', background: 'transparent'},
          xaxis: 	 { type: 'datetime' , categories:[''] , },
          fill: 	 { type:'solid'	}   ,
          stroke:  { curve: 'smooth'	, width: 1 },
          markers: { size: [2.5] , strokeWidth: 0, },
          tooltip: { shared: false,  x: {
              show: true,
              format: 'dd/MM HH:mm',
              formatter: undefined,
          }, },
          colors: ['#fce303', '#16b526','#134985'],
          yaxis: {
              labels: {
                  formatter: function (value:any) {
                    return value.toFixed(0);
                  }
              },
          },  
        }
                
        for(let i =0; i <= list.length-1 ; i++){
            optionupdate.xaxis.categories[i] = moment(list[i].time).format('HH:mm:ss')
            seriesLine.data[i] = [  moment(list[i].time).add(7, 'hours').format() , parseFloat(list[i].gasolone)  ]
        }
                
        if( yellowArr.length > 0 ){
            for(let i=0 ; i <= yellowArr.length-1 ; i++){
                seriesYellow.data[i] = [  moment(yellowArr[i].time).add(7, 'hours').format() ,parseFloat(yellowArr[i].gasolone)  ]
            }
        } 
        if( greenArr.length > 0 ){
            for(let i=0 ; i <= greenArr.length-1 ; i++){
                seriesGreen.data[i] = [moment(greenArr[i].time).add(7, 'hours').format() , parseFloat(greenArr[i].gasolone) ]
            }
        } 
        let yellow = '#fcec03'
        let green = '#16b526'
        let blue = '#306bc9'
        optionupdate.colors=[yellow,green,blue] 

        seriesupdate[0]= seriesYellow 
        seriesupdate[1]= seriesGreen
        seriesupdate[2]= seriesLine 
        let  tableOverview = [ 
           [{ colSize: "4" , label: "วันที่ "}    ,{ colSize: "" , label: result?.date      }],
           [{ colSize: "4" , label: "ทะเบียน "}    ,{ colSize: "" , label: vehihle?.label      }],
           [{ colSize: "4" , label: "ระยะทาง"} ,{ colSize: "" , label: result?.distance  +" กม."}],
           [{ colSize: "4" , label: "ค่าใช้จ่าย"} ,{ colSize: "" , label: result?.expenses  +" บาท"}],
           [{ colSize: "4" , label: "น้ำมันที่ใช้"} ,{ colSize: "" , label: result?.fuelUsaged+" ลิตร"}],
        ]
 
        reportContent  = { ...{ 
            table: table ,
            tableOverview: tableOverview ,
            chart: {
             series : seriesupdate ,
             option : optionupdate
            } ,
            detail: {
              name: vehihle.label ,
              reportName: report?.name,
              start: datestart ,
              end: dateend ,
              ...result
            } ,
            success: result ? true : false,
          },
        ...{ overview: result} } 
  
      }else if(report.id === "0021"){
        let result = await repCarsDailyUse({
          deviceId: vehihle?.device_id,
          start: new Date(datestart).getTime() ,
          end: new Date(dateend).getTime()
        })
        console.log("result ",result)
        let overview = result.overview 
        let moveTime = convertMsToTime(overview.move)
        let stopTime = convertMsToTime(overview.stop)
        let idleTime = convertMsToTime(overview.idle) 
  
        result?.events.length >0 ? success=true : success=false

         let res = {
            overview:{
              maxSpeed:  overview.maxSpeed,
              move: overview.move,
              idle: overview.stop,
              stop: overview.idle
            },
            events: result.events.filter((e:any)=> e.start > 0&& e.end > 0)
           }
         const rep = await setReport0021(result)
         reportContent = {  
            table:{
              headers: rep.table2Head ,
              rows: rep.table2Rows
            },
            overview: rep.tableOverviewColumn,
            details:{
              name: vehihle.label ,
              start: datestart ,
              end: dateend,
              reportName: report?.name,
              pttprice: overview.lastPttPrice
            }, 
            success: success
         }
        console.log("reportContent ",reportContent) 
   
      }else if(report.id === "0023"){
        let result = await repMinuteDaily({
          deviceId: vehihle?.device_id,
          start: new Date(datestart).getTime() ,
          end: new Date(dateend).getTime()
        }) 
        let resp  :any ={ table:[],maxSpeed:[]}
        if(result.minutes.length > 0){
          resp = await setReport0023(result) 
          success = true
        }
        reportContent= {
          success: success ,
          table: resp.table,
          maxSpeed: resp.maxSpeed,
          detail: {
            start: moment(datestart).format("DD MMM YYYY") ,
            end:  moment(dateend).format("DD MMM YYYY"), 
            name: vehihle.label ,
            reportName: report?.name,
          },
          details : {
            start: datestart ,
            end: dateend, 
            name: vehihle.label ,
            reportName: report?.name,
          }
        } 

      }else if(report.id === "0026"){ 
        let rangeDate:any =[]
        
        var range = moment().range(new Date(datestart), new Date(dateend));
        var diff = range.diff('days'); 
  
        for(let i=0; i<= diff; i++){
          let day =moment(datestart).add(i,'day').format("YYYY-MM-DDT")
          console.log("day ",day)
          rangeDate.push({ 
            start: new Date(day+"00:00:00").getTime(),
            end: new Date(day+"23:59:59").getTime(),
          })
        } 
        let body = {
          rangeDate: rangeDate ,
          deviceId: vehihle.device_id
        }
  
        let report = await repDistanceDaily(body)
        let table = await setReport0026(report) 
        if(table?.rowData.length > 0){  success = true }
         
        reportContent= {
          success: success ,
          table: table,
          details: {
            start: moment(datestart).format("DD MMM YYYY") ,
            end:  moment(dateend).format("DD MMM YYYY"), 
            vehicle: vehihle.label
          }
        } 
      }else if(report.id === "0027"){ 
        let rangeDate:any =[]
        let rangeDatestr = ''
        var range = moment().range(new Date(datestart), new Date(dateend));
        var diff = range.diff('days'); 
  
        for(let i=0; i<= diff; i++){
          let day = moment(datestart).add(i,'day').format("YYYY-MM-DDT")
          console.log("day ",day)
          rangeDate.push({ 
            start: new Date(day+"00:00:00").getTime(),
            end: new Date(day+"23:59:59").getTime(),
          })
          rangeDatestr = rangeDatestr+`${new Date(day+"00:00:00").getTime()},${new Date(day+"23:59:59").getTime()}#`
        } 
        vehihcles = vehihcles.filter((e:any)=> e!= 0)

        let body = {
          start: rangeDate[0].start ,
          end: rangeDate[rangeDate.length-1].end ,
          rangeDate: rangeDate ,
          rangeDatestr: rangeDatestr  ,
          devices: vehihcles //ids
        }
        console.log("0027 body ids ",body)
        let res = await  repRangDistance(body)
        console.log("repRangDistance res ",res)
        success = true
        let headers:any[] = res?.theaders
        headers.unshift({no: -1, label: 'ทะเบียน'})
        headers.unshift({no: -2, label: 'ลำดับ'})
        reportContent= { 
          success: success ,
          table: {
            theaders : headers ,
            trows: res?.trows
          } ,
          details:{
            start: moment(datestart).format("DD/MMM/YYYY") ,
            end: moment(dateend).format("DD/MMM/YYYY") ,
            name: '' ,
            reportName: report?.name,
          },
          origin:res
        } 


      }else if(report.id ===  "0031"){
        let result = await repCarsDailyUse({
          deviceId: vehihle?.device_id,
          start: new Date(datestart).getTime() ,
          end: new Date(dateend).getTime()
        })
        console.log("result ",result) 
         let res = { 
            events: result.events.filter((e:any)=> e.start > 0&& e.end > 0 && e.status === 23)
           }
         const rep = await setReport0031(res)
        if( res.events.length > 0 ){ success = true }else{ success = false }

        reportContent= { 
          success: success ,
          table:rep.table, 
          details:{
            start: datestart ,
            end: dateend,
            name: vehihle.label ,
            reportName: report?.name,
          }
        } 
      }else if(report.id === "0032"){
        let result = await repCarsDailyUse({
          deviceId: vehihle?.device_id,
          start: new Date(datestart).getTime() ,
          end: new Date(dateend).getTime()
        })
        console.log("result ",result) 
          let res = { 
            events: result.events.filter((e:any)=> e.start > 0&& e.end > 0 && e.status === 24)
           }
          if( res.events.length > 0 ){ success = true }else{ success = false }
        const rep = await setReport0031(res)
        reportContent= { 
          success : success ,
          table: rep.table, 
          details:{
            start: datestart ,
            end: dateend,
            name: vehihle.label ,
            reportName: report?.name,
          }
        } 
      }else if(report.id === "0033"){
        console.log("vehicle ",vehihle)
        console.log("datestart ",datestart) 
        console.log("dateend ",dateend)

        let result = await reportIgnition({
          deviceId: vehihle?.device_id,
          start: new Date(datestart).getTime() ,
          end: new Date(dateend).getTime()
        })
        console.log("reportIgnition ",result)
        if(result.length > 0){
            let res = await setReport0033(result)  
            reportContent = { 
               success : true ,
               table: res, 
               details:{
                 start: datestart,
                 end:  dateend,
                 name: vehihle?.label ,
                 reportName: report?.name,
               }
             }  
             console.log("reportContentv ",reportContent)
        }else{
            reportContent = { 
              success : false ,
                table: tableNoData, 
                details:{
                    start: datestart ,
                    end: dateend,
                    name: vehihle.label ,
                    reportName: "รายงานเปิด-ปิด กุญแจ"
                }
            }
        }
       
      }else if(report.id === "0034"){ 
        reportContent = { 
          success : false ,
            table: tableNoData, 
            details:{
                start: datestart ,
                end: dateend,
                name: vehihle.label ,
                reportName: "รายงานเปิด-ปิด กุญแจ"
            }
        }
      }else if(report.id === "0035"){
        let result = await repClosOpenPTO({
          deviceId: vehihle?.device_id,
          start: new Date(datestart).getTime() ,
          end: new Date(dateend).getTime()
        })
        console.log("result ",result) 
        if(result.length > 0){
            let res = await setReport0035(result) 
            reportContent = { 
              success : true ,
                table: res, 
                details:{
                    start: datestart ,
                    end: dateend,
                    name: vehihle.label ,
                    reportName:report?.name,
                } 
            } 
        } else{
            reportContent = { 
              success : false ,
              table: tableNoData, 
              details:{
                start: datestart ,
                end: dateend,
                name: vehihle.label ,
                reportName:report?.name,
              }
            }
        }
      }else if(report.id === "0041"){
        let result =await reportInOutGeozoe({
          devices: vehihcles ,
          start: new Date(datestart).getTime() ,
          end: new Date(dateend).getTime(),
          geozoneid: more?.geozoneid
        })
        let res = await setReport0041(result)
        console.log("res ",res)
        reportContent = { 
          success : true ,
          table: res.table ,
          details:{
            start: datestart ,
            end: dateend,
            name: vehihle.label ,
            reportName: report?.name,
          }
        }
      }else if(report.id === "0042"){ 
        let result =await reportOutInGeozoe({
          devices: vehihcles ,
          start: new Date(datestart).getTime() ,
          end: new Date(dateend).getTime(),
          geozoneid: more?.geozoneid
        })
        let res = await setReport0042(result)
        console.log("res ",res)
        reportContent = { 
          success : true ,
          table: res.table ,
          details:{
            start: datestart ,
            end: dateend,
            name: vehihle.label ,
            reportName: report?.name,
          }
        }
      }else if(report.id === "0043"){ 
        let result =await reportDeviceInOutGeozoe({
          devices: vehihcles ,
          start: new Date(datestart).getTime() ,
          end: new Date(dateend).getTime(),
          geozoneid: more?.geozoneid
        })
        let res = await setReport0043(result)
        console.log("res ",res)
        reportContent = { 
          success : true ,
          table: res.table ,
          details:{
            start: datestart ,
            end: dateend,
            name: vehihle.label ,
            reportName: report?.name,
          }
        }
      }//reportDeviceInOutGeozoe
      else if(report.id === "0051"){  
      const dateStart = moment(datestart).format("DD/MM/YYYY")
      const dateEnd = moment(dateend).format("DD/MM/YYYY") 
       let result =await summarypiechart({
          deviceId: vehihle?.device_id ,
          dateStart: dateStart ,
          dateEnd:dateEnd
       })
       let res = await setReport0051(result)
       console.log("result ",result)

       reportContent = { 
        success : true ,
        chart: res ,
        details:{
          start: datestart ,
          end: dateend,
          name: vehihle.label ,
          reportName: report?.name,
        }
       }
      }else if(report.id === "0052"){ 
        console.log("vehihle ",vehihle)
        let result:any[] =await repGasolone({
          deviceId: vehihle?.device_id ,
          start: new Date(datestart).getTime() ,
          end: new Date(dateend).getTime()
       })
       let res = await setReport0052(result) 

       reportContent = { 
        success : true ,
        chart: res ,
        details:{
          start: datestart ,
          end: dateend,
          name: vehihle.label ,
          reportName:report?.name,
        }
      }
      }else if(report.id === "0053"){
        let result:any[] =await repTemperature({
          deviceId: vehihle?.device_id ,
          start: new Date(datestart).getTime() ,
          end: new Date(dateend).getTime()
        }) 
       console.log("0053 result ",result) 
       let res = await setReport0053(result)
       console.log("0053 res ",res) 

       reportContent = { 
        success : true ,
        chart: res ,
        details:{
          start: datestart ,
          end: dateend,
          name: vehihle.label ,
          reportName:report?.name,
        }
      } 
      }else if(report.id === "0054"){
        const begin = moment(datestart).format("YYYY-MM-DD")
        const body = {
          deviceId: vehihle?.device_id ,
          start: new Date(datestart).getTime() ,
          end: new Date(dateend).getTime()
        }
        const rangestart = new Date(datestart);
        const rangeend   = new Date(dateend); 
        const range1 = moment.range(rangestart, rangeend); 
        const range = range1.diff('days')  
       

        let formbody = []
      
        let datebegin =moment(datestart).format()
        for(let i=0; i<=range;i++){
            formbody.push({
                deviceId: vehihle?.device_id ,
                date:  moment(datebegin).format('DD/MM/YYYY') ,
                start: new Date(moment(datebegin).format("YYYY-MM-DD 00:00:00")).getTime(),
                end: new Date(moment(datebegin).format("YYYY-MM-DD 23:59:59")).getTime(),
            }) 
            datebegin = moment(datebegin).add(1,'day').format()
        }  
        let result:any =await repStatusColumn({body:formbody,begin,range})
        console.log("repStatusColumn result ",result)
        reportContent = { 
          success : true ,
          chart: result ,
          details:{
            start: datestart ,
            end: dateend,
            name: vehihle.label ,
            reportName:report?.name,
          }
        } 

      }else if(report.id === "0055"){
        const begin = moment(datestart).format("YYYY-MM-DD")
        const body = {
          deviceId: vehihle?.device_id ,
          start: new Date(datestart).getTime() ,
          end: new Date(dateend).getTime()
        } 
        const rangestart = new Date(datestart);
        const rangeend   = new Date(dateend); 
        const range1 = moment.range(rangestart, rangeend); 
        const range = range1.diff('days')  
        let formbody = []
        vehihcles = vehihcles.filter((e:any)=> e !== 0)
        let datebegin =moment(datestart).format()
        for(let i=0; i<vehihcles.length;i++){
            formbody.push({
                deviceId: vehihcles[i] ,
                date:  moment(datebegin).format('DD/MM/YYYY') ,
                start: new Date(moment(datebegin).format("YYYY-MM-DD 00:00:00")).getTime(),
                end: new Date(moment(datebegin).format("YYYY-MM-DD 23:59:59")).getTime(),
            }) 
            // datebegin = moment(datebegin).add(1,'day').format()
        }  
        let result:any =await repStatusDaily({body:formbody,begin,range})
      
        reportContent = { 
          success : true ,
          chart:result ,
          details:{
            start: datestart ,
            end: dateend,
            name: vehihle.label ,
            reportName:report?.name,
          }
        } 
      }else if(report.id === "0056"){  
        let result:any[] =await repGasoloneSpeed({
          deviceId: vehihle?.device_id ,
          start: new Date(datestart).getTime() ,
          end: new Date(dateend).getTime()
       })
       let res = await setReport0056(result) 

       reportContent = { 
          success : true ,
          chart: res ,
          details:{
            start: datestart ,
            end: dateend,
            name: vehihle.label ,
            reportName:report?.name,
          }
        }
      } 
       else if(report.id === "0061"){
        let result:any[] =await repOverSpeed({
          deviceId: vehihle?.device_id ,
          start: new Date(datestart).getTime() ,
          end: new Date(dateend).getTime()
       }) 
       let table = await setReport0061(result)
       reportContent = { 
        success : result.length > 0 ? true : false ,  
        table:{
          headers: table.columns ,
          rows: table.rowData
        },
        details:{
          start: datestart ,
          end: dateend,
          name: vehihle.label ,
          reportName: report?.name,
        }
      }  
      }else if(report.id === "0062"){
        let result:any[] =await repOverTime({
          deviceId: vehihle?.device_id , 
          start: new Date(datestart).getTime() ,
          end: new Date(dateend).getTime()
       }) 
       let table = await setReport0061(result)
       reportContent = { 
        success : result.length > 0 ? true : false ,  
        table:{
          headers: table.columns ,
          rows: table.rowData
        },
        details:{
          start: datestart ,
          end: dateend,
          name: vehihle.label ,
          reportName: report?.name,
        }
      }
      }else{
        reportContent = { 
          success : false 
        }
      } 

    return reportContent
}
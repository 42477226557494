import { IonButton, IonCol, IonContent, IonIcon, IonLabel, IonRow, IonText, IonTitle } from "@ionic/react"; 
import { printOutline } from "ionicons/icons";
import moment from "moment"; 
import { TableIginition, TablePTOCloseOpen } from "./ReportComponent/ReportTable";
import { pdfMake } from "../../lib/pdfMake" 

const RepCloseOpenPTO=({details,table}:any)=>{
 
    const printReport=()=>{
        let bodyTable:any = []  
        let headers = table.header.map((el:any)=>{ 
             return  {text: el.label.toUpperCase()  , alignment:'center', bold:true  } 
        })
        table.rowData.map((rows:any)=>{  
            let row =  rows.cols.map((cols:any)=>{  
                return  cols.id === "status" ? {text: cols.label?"ประตูเปิด":"ประตูปิด" , alignment:'center' } :{text: cols.label , alignment:'center' }   
            })
            bodyTable.push(row)
        })  
        bodyTable.unshift(headers)
        
        pdfMake.createPdf({ 
            watermark: { text: 'Andaman Tracking', color: '#FD5F06', opacity: 0.1, bold: true, italics: false },
            content:[  
                {text: details.reportName , fontSize: 16 , bold:true  , alignment:'center', marginBottom: 15},
                {text:`วันที่ ${moment(details.start).format("DD MMMM   HH:mm")} - ${moment(details.end).format(" DD MMMM  HH:mm")} `, alignment:'center'},
                {text:`ทะเบียนรถ  ${details?.name} `, alignment:'center'}, 
                {text:""},
                {   
                    layout: 'lightHorizontalLines',
                    marginTop: 10 ,
                    table:{
                      dontBreakRows:true,
                      headerRows: 1,
                      widths: headers.map((e:any)=>{return  e =="address" ? "*":e =="time"?"*" : "auto"}),
                      body: bodyTable  
                    }
                  } 
            ],
            defaultStyle: {
                font: "THSarabunNew",
            },
            header: function (currentPage, pageCount) {
                return {
                    columns: [
                        { 
                            text: details.reportName ,
                            fontSize: 10 ,
                            marginLeft: 16,
                            margin: [20, 20, 50, 20] 
                        } ,
                        { text: "Page " + currentPage.toString() + ' of ' + pageCount, alignment: 'right', style: 'normalText', margin: [0, 20, 50, 0] }
                    ]
                }
        },
            footer: {
                columns: [
                    { 
                        text:"บริษัท อันดามันแทร็คกิ้ง จำกัด | Andaman Tracking .Co.Ltd  @Generate on  tracking.andamantracking.com" ,
                        fontSize: 9 ,
                        marginLeft: 15
                    } 
                ]
            },
        }).open()
    }

    return(
        <div className="ion-padding " style={{position:"relative"}} >
        <IonButton  
            onClick={()=>{printReport()}}
            mode="ios" fill="clear" 
            style={{position:"relative", top:"-1rem", right:"1rem"}} 
        >
            <IonLabel  style={{fontSize:".8em"}}>Print</IonLabel>  &nbsp;
            <IonIcon icon={printOutline}  /> 
        </IonButton> 
        <IonRow className="ion-margin-bottom" >
            <IonCol size="12" className="ion-text-left ion-padding-left" >
                <IonTitle className="ion-margin-bottom ion-no-padding">{details.reportName}</IonTitle> 
 
                <IonLabel style={{fontSize:'.85em'}}>
                    <IonText className="ion-margin-end" >วันที่</IonText> 
                    <IonText>{moment(details.dateStart).format("DD MMMM   HH:mm")}</IonText> -
                    <IonText>{moment(details.dateEnd).format(" DD MMMM  HH:mm")}</IonText>
                </IonLabel><br/>
                <IonLabel style={{fontSize:'.9em'}}>
                    <IonText>ทะเบียนรถ​ : {details?.name} </IonText>
                </IonLabel>
            </IonCol>
        </IonRow>
        <IonRow  className="ion-justify-content-center" >
            <IonCol size="12" >
                <TablePTOCloseOpen headers={table.header} rowsData={table.rowData} />
            </IonCol>
        </IonRow>
        </div>
    )
}
export default RepCloseOpenPTO;
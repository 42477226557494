import { IonButton, IonCol, IonContent, IonIcon, IonLabel, IonRow, IonText, IonTitle } from "@ionic/react"
import { ReportSpeedFuel } from "./ReportComponent/Schemas"; 
import moment from "moment"; 
import { TableSpeedTemp } from "./ReportComponent/ReportTable";
import "./Reports.css"
import 'moment/locale/th' 
import { ChrtSpeedFuel } from "./ReportComponent/ReportChart";
import { printOutline } from "ionicons/icons";
import { useState } from "react"; 
import { pdfMake } from "../../lib/pdfMake" 


const RepSpeedFuel  =({table,detail,chart,orientation}:ReportSpeedFuel)=>{ 
    const [chrtImg,setChrtImg] = useState("")
    const printReport=()=>{ 
        let bodyTable:any = []
        let headers = table.headers.map((el:any)=>{
             return  {text: el.label  , alignment:'center', bold:true  } 
        })
        table.rows.map((rows:any)=>{  
            let row =  rows.cols.map((cols:any)=>{
                console.log("cols ",cols)
                return  {text: cols.label  , alignment:'center' }  
            })
            bodyTable.push(row)
        }) 
        bodyTable.unshift(headers)
        
        pdfMake.createPdf({
            images: {
                chart: chrtImg
            },
            content:[  
                {text:'รายงาน ความเร็วและการใช้น้ำมัน' , fontSize: 16 , bold:true  , alignment:'center', marginBottom: 15},
                {text:`วันที่ ${moment(detail.dateStart).format("DD MMMM   HH:mm")} - ${moment(detail.dateEnd).format(" DD MMMM  HH:mm")} `, alignment:'center'},
                {text:`ทะเบียนรถ  ${detail?.device?.name} `, alignment:'center'},
                {image: 'chart' , width:   450  , alignment:'center' ,  marginTop: 20 ,},
                {text:`ตารางแสดงความเร็ว และการใช้น้ำมัน ` },
                {   
                    layout: 'lightHorizontalLines',
                    marginTop: 10 ,
                    table:{
                      dontBreakRows:true,
                      headerRows: 1,
                      widths:['auto',"*","*","*"],
                      body: bodyTable  
                    }
                  } 
            ],
            defaultStyle: {
                font: "THSarabunNew",
            }
        }).open()

    }

    return(
    <div className="ion-padding" >
        <IonButton  
            onClick={()=>{printReport()}}
            mode="ios" fill="clear" 
            style={{position:"relative", top:"1rem", right:"1rem"}} 
        >
            <IonLabel  style={{fontSize:".8em"}}>Print</IonLabel>  &nbsp;
            <IonIcon icon={printOutline}  /> 
        </IonButton>
        <IonRow className="ion-margin-bottom" >
            <IonCol size="12" className="ion-text-center" >
                <IonTitle className="ion-margin-bottom">รายงาน ความเร็วและการใช้น้ำมัน</IonTitle> 

                <br />
                <IonLabel style={{fontSize:'.9em'}}>
                    <IonText className="ion-margin-end" >วันที่</IonText> 
                    <IonText>{moment(detail.dateStart).format("DD MMMM   HH:mm")}</IonText> -
                    <IonText>{moment(detail.dateEnd).format(" DD MMMM  HH:mm")}</IonText>
                </IonLabel><br/>
                <IonLabel style={{fontSize:'.9em'}}>
                    <IonText>ทะเบียนรถ​ : {detail?.device?.name} </IonText>
                </IonLabel>
            </IonCol>
        </IonRow>
        <IonRow  className="ion-justify-content-center" >
            <IonCol size="10" >
                <ChrtSpeedFuel 
                    options={{...options, ...{series:chart.series ,labels :chart.labels} }}  
                    getUri={(e:any)=>{ setChrtImg(e);console.log("getUri ",e)}}
                />
                <br/>
                <TableSpeedTemp headers={table.headers} rowsData={table.rows}  />
            </IonCol>
        </IonRow>
    </div>

    )
}

export default RepSpeedFuel;

var options:any =  {
    id:'report-realtime',
    series: [{
        name: 'Device1',
        data: []
    }],
    chart: {
        height: 200,
        type: 'line',
    }, 
    labels:[],
  grid: {
     show: true,
     borderColor: '#FCFCFC',
     strokeDashArray: 0,
     position: 'back',
     xaxis: {
         lines: {
             show: true
         }
     },   
     yaxis: {
         lines: {
             show: true
         }
     }, 
        }, 
    stroke: {
        width: 3,
        curve: 'smooth'
    },
    xaxis: {
        show:false,
        type: 'datetime',
        categories: [],
        tickAmount: 10,
        labels: {
        show:false,
        formatter: function(value:any, timestamp:any, opts:any) {
            return opts.dateFormatter(new Date(timestamp), 'dd MMM')
        }
        }
    }, 
    colors:["#5FDDC7","#a145f7"],
    fill: {
        type: 'gradient',
        gradient: {
        shade: 'dark',
        gradientToColors: [ '#FFDEC0', '#d445f7'],
        shadeIntensity: 0,
        type: 'vertical',
        opacityFrom: 1,
        opacityTo: 1, 
        },
    },
    yaxis: {
        // min: -10,
        // max: 10 , 
        labels: {
            style: {
                colors: ["#EEE"],
                fontSize: '12px', 
                fontWeight: 400, 
            },
        }
    },
    tooltip: {
        y: {
          formatter: function(value:any, { series, seriesIndex, dataPointIndex, w }:any) { 
            return value
          }
        },
        x: {
            formatter: function(value:any, { series, seriesIndex, dataPointIndex, w }:any) { 
              return moment(value).format("HH:mm:ss DD/MMM/YY")
            }
          }
      }
  };
import { IonButton, IonCol, IonContent, IonGrid, IonIcon, IonLabel, IonRow, IonText } from "@ionic/react"
import { TableEvents, TableOverview } from "./ReportComponent/ReportTable";
import moment from "moment";
import { printOutline } from "ionicons/icons";
import { pdfMake } from "../../lib/pdfMake" 
import * as Excel from "exceljs";
import 'sheetjs-style' 
import FileSaver from "file-saver";  
import { borderCells } from "../../lib/Excels";

const RepCarDailyUse=({ table,overview,details,orientation}:any)=>{
 
    const printReport=()=>{
        let bodyTable:any = []
        let tableOverview:any = [] 
        table.rows.map((rows:any)=>{  
            let row =  rows.cols.map((cols:any)=>{
                console.log("cols ",cols)
                return  {text: 
                    cols.id === "status" && cols.label === 7  ? `(${cols.label}) Move` :
                    cols.id === "status" && cols.label === 24  ?`(${cols.label}) Idle` :
                    cols.id === "status" && cols.label === 23  ?`(${cols.label}) Stop` 
                :cols.label  , alignment:'center' }  
            })
            bodyTable.push(row)
        }) 
        overview.map((rows:any)=>{  
            let row =  rows.map((cols:any)=>{
                console.log("cols ",cols)
                return  {text: cols.label  , alignment: 'left' }  
            })
            tableOverview.push(row)
        }) 
        bodyTable.unshift([ 
            {text:'ลำดับ', alignment:'center'}, 
            {text:'สถานะ' , alignment:'center'}, 
            {text:'เริ่มต้น' , alignment:'center'}, 
            {text:'สิ้นสุด' , alignment:'center'}, 
            {text:'ระยะเวลา' , alignment:'center'}, 
            {text:'ระยะทาง' , alignment:'center'}, 
            {text:'ค่าใช้จ่าย' , alignment:'center'}, 
            {text:'คนขับ' , alignment:'center'}, 
            {text:'ความเร็วสูงสุด' , alignment:'center'}, 
            {text:'สถานที่' , alignment:'center'},  
        ])
        console.log("bodyTable ",bodyTable)


        pdfMake.createPdf({
            pageOrientation: orientation, 
            content:[  
                {text: details?.reportName , fontSize: 16 , bold:true  , alignment:'center', marginBottom: 15},
                {text:`วันที่ ${moment(details.start).format("DD MMMM   HH:mm")} - ${moment(details.end).format(" DD MMMM  HH:mm")} `, alignment:'center'},
                {text:`ทะเบียนรถ  ${details?.name} `, alignment:'center'}, {text:``}, 
                {text:`ภาพรวม `, bold: true, alignment:'left'}, 
                {   
                    layout: 'noBorders',
                    marginTop: 10 ,
                    table:{
                      dontBreakRows:true,
                      headerRows: 1,
                      widths:[60,100],
                      body: tableOverview  
                    }
                } ,
                {   
                    layout: 'lightHorizontalLines',
                    marginTop: 10 ,
                    table:{
                      dontBreakRows:true,
                      headerRows: 1,
                      widths:[ 30,'auto','auto',"auto","auto","auto","auto","auto","auto","auto"],
                      body: bodyTable  
                    }
                  },
                {text:''} ,
                {text: details.pttprice , bold: true, alignment:'center'},  
            ],
            defaultStyle: {
                font: "THSarabunNew",
            }
        }).open() 
    }


    const printExcel=async ()=>{
        try{   
            var options = {
                filename: './streamed-workbook.xlsx',
                useStyles: true,
                useSharedStrings: true
            }; //@ts-ignore 
            let workbook = new Excel.Workbook(options);
            var ws = workbook.addWorksheet( "AddRoute" , {properties:{tabColor:{theme:1}}});
            
            ws.columns =[{width:10},{width: 15},{width: 25},{width: 25},{width: 15},{width: 15},{width: 15},{width: 15},{width: 15},{width: 50}] 
            // ws.getRow(1).alignment = {horizontal:'center' , vertical:'middle' }

            const header = ["ลำดับ","สถานะ","เริ่มต้น","สิ้นสุด","เวลาสถานะ","ระยะทาง","ค่าใช้จ่าย","คนขับ","ความสูงสุด","สถานที่"]
            ws.mergeCells('A1', 'J1'); 
            ws.getCell("A1").value = "รายงานการใช้รถ"
            ws.mergeCells('A2', 'J2'); 
            ws.getCell("A2").value =  `${moment(details.start).format("DD MMMM   HH:mm")} - ${moment(details.end).format(" DD MMMM  HH:mm")}`
            ws.getCell("A2").alignment = { vertical: 'middle', horizontal: 'center', wrapText: true }; 
            ws.addRow([''])
            ws.addRow(['','ภาพรวม'])
            await overview.map(async(rows:any)=>{  
               let row =  await rows.map((cols:any)=>{
                    console.log("cols ",cols)
                   return cols.label
                })  
                console.log("row ", row) 
                row.unshift('')
                ws.addRow(row) 
            }) 
            ws.addRow([''])
            ws.addRow([''])

            ws.addRow(header)
            const rowheader = ws.getRow(1); 
            rowheader.eachCell({includeEmpty: true}, ((cell:any) => {
                cell.alignment = { vertical: 'middle', horizontal: 'center', wrapText: true }; 
                cell.font = { bold: true ,  family: 4, size: 16, underline: true, }
                cell.border = borderCells
            }));  

            table.rows.map((rows:any)=>{  
                let row =  rows.cols.map((cols:any)=>{
                    return  cols.label   
                })  
                console.log("rows ",rows)
                
                let rowadded = ws.addRow(row)
                rowadded.height =   rowadded.height*2 ;
                rowadded.eachCell({includeEmpty: true}, ((cell:any,column:any) => {  
                    cell.border = borderCells 
                    cell.inset= [0.25, 0.25, 0.35, 0.35]
                    cell.alignment = { vertical: 'middle', horizontal: 'center', wrapText: true }; 
                }));   
            }) 
            ws.addRow([''])  
            ws.getCell(`A${table.rows.length+15}`).value = details.pttprice
            
            let fileName=`${details?.name}-${moment(details.start).format("DD MMMM   HH:mm")} - ${moment(details.end).format(" DD MMMM  HH:mm")}.xlsx`;  
            workbook.xlsx.writeBuffer() 
            .then(function(buffer:any) { 
                const data: Blob = new Blob([buffer], {type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'});
                FileSaver.saveAs(data, fileName);
            }); 

            
        }catch(err){
            console.log("errr ",err)
        }
    }

    return(<div >    
        <IonButton  
            onClick={()=>{printReport()}}
            mode="ios" fill="clear" 
            style={{position:"relative", top:"1rem", right:"1rem"}} 
        >
            <IonLabel  style={{fontSize:".8em"}}>Print</IonLabel>  &nbsp;
            <IonIcon icon={printOutline}  /> 
        </IonButton>
        <IonButton onClick={()=>{printExcel()}}  
            mode="ios" fill="clear"  
            style={{position:"relative", top:"1rem", right:"1rem"}}   >
            <IonLabel style={{color:"#12753D", fontSize:".8em"}}> Excel </IonLabel> &nbsp;
            <img src="../assets/icon/excel.svg" style={{width:"1.7rem"}} />
        </IonButton>
        <IonGrid>
            <IonRow> 
                <IonCol size="12" >
                    <IonLabel style={{fontSize:"1.2em",fontWeight:"bold"}} >{details.reportName}</IonLabel><br/>
                    <IonLabel style={{fontSize:'.8em'}}>
                        <IonText>วันที่ {moment(details.start).format("DD MMM YYYY HH:mm")}</IonText>
                        <IonText>-</IonText>
                        <IonText>วันที่ {moment(details.end).format("DD MMM YYYY HH:mm")}</IonText>
                    </IonLabel>
                </IonCol>
            </IonRow><br/>
            <IonRow> 
                <IonCol size="12" >
                    <IonLabel>
                        สรุปภาพรวม
                    </IonLabel>
                </IonCol>
                <IonCol size="12" > 
                    <TableOverview overview={overview}  />
                </IonCol>
            </IonRow>

            <IonRow> 
                <IonCol size="12" > 
                    <IonLabel>
                    รายการการเดินทาง
                    </IonLabel>
                </IonCol>
                <IonCol size="12" >  
                   <TableEvents headers={table.headers} rowsData={table.rows} />
                </IonCol>
                <IonCol size="12" className="ion-text-center">  
                   <small> {details.pttprice}</small>
                </IonCol>
            </IonRow>
        </IonGrid>
    </div>)
}

export default RepCarDailyUse;
import { IonButton, IonButtons, IonCheckbox, IonCol, IonContent, IonGrid, IonHeader, IonIcon, IonImg, IonItem, IonLabel, IonList, IonPage, IonRow, IonSearchbar, IonText, IonTitle, IonToolbar } from "@ionic/react"
import { checkmarkCircle, chevronBackOutline, chevronDown, chevronUpCircle, ellipse, searchCircle } from "ionicons/icons";
import NavBar from "../../components/NavBar";
import { useHistory } from "react-router";
import { useEffect, useState } from "react";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";  
import { getMdvrVehicles, getMdvrVehiclesBackup, setMdvrVehicle, setMdvrVehicleBackup } from "../../store/menuSlice";
import { Tabs, TabList, Tab, TabPanel } from "react-tabs";

import { DeviceMdvr } from "./MdvrSchema";
import { getStorage } from "../../actions";
import { SiginDetail } from "../schema";
import "../css/PlayBack.css"

let devicemock:any[] = [{dl:[{cc:4 ,cn: "CH1,CH2,CH3,CH4", id:"4sd000066"  }],nm:'',chnName:"",isOnline:0}]


const RealtimeMdvr=()=>{
    const history = useHistory()
    const [layout,setLayout] = useState(4)
    const chanelLayout = [
        {layout: 1 , icon:"../../assets/icon/layout_1.png", iconSelect:"../../assets/icon/layout_1-select.png"},
        {layout: 4 , icon:"../../assets/icon/layout_4.png", iconSelect:"../../assets/icon/layout_4-select.png"},
        {layout: 6 , icon:"../../assets/icon/layout_6.png", iconSelect:"../../assets/icon/layout_6-select.png"},
        {layout: 8 , icon:"../../assets/icon/layout_8.png", iconSelect:"../../assets/icon/layout_8-select.png"},
        {layout: 9 , icon:"../../assets/icon/layout_9.png", iconSelect:"../../assets/icon/layout_9-select.png"}, 
    ]
    const [vehicleno ,setVehicleNo] = useState<any>(null)  
    const [JSESSIONID,serJsessionId] = useState(null)
    const [devices,setDevices] = useState<any[]>(devicemock)
    const [accordion,setAccordion] = useState("")
    const [vehiclePlate,setVehiclePlate] = useState("")
    const [chn,setChn] = useState(0)
    const [deviceOnline,setDeviceOnline] = useState([ { "did":"500000", "vid": null, "online":1  }] )
    const [sims,setSims] = useState([])
    const [simstr,setSimStr] = useState("")
    const [chnDisplay,setChanDisplay] = useState("0,1,2,3")
    const dispatch = useDispatch()

    const [deviceMdvr,setDeviceMdvr] = useState<DeviceMdvr|null>(null)
    const vehicleMdvr = useSelector(getMdvrVehicles)
    const vehicleBackup = useSelector(getMdvrVehiclesBackup)
    const [siginde,setSigninde] = useState<SiginDetail|null>(null)
    
     const getsignin=async()=>{
        let s = await getStorage("sigin")
        console.log("getsignin ",s)
        setSigninde(s)
     }

    useEffect(()=>{ 
        getsignin() 
        setDeviceOnline([])
        loginmdvr()
    },[JSESSIONID , chnDisplay])

  
    const findSimAll=async(jsession:any )=>{
        await axios.get("http://mdvr.attg.cc/StandardApiAction_loadSIMInfos.action?jsession="+jsession).then(res =>{
            console.log("findSimAll ",res )
            if(res.data.result === 0){
                setSims(res.data.infos) 
            }
        })  
    }
    const findSim=async(jsession:any, simid:any)=>{
        await axios.get("http://mdvr.attg.cc/StandardApiAction_findSIMInfo.action?jsession="+jsession+"&id="+simid).then(res =>{
            console.log("findSim ",res )
            setSimStr(JSON.stringify(res.data.sim))
        }).catch((err)=>{
            setSimStr(JSON.stringify(err))
        })
    }
    const loginmdvr=async()=>{
        const sigin = await getStorage("sigin") 
        setSigninde(sigin)
        if(sigin && sigin.username.indexOf("admin") > -1){
            if(!JSESSIONID){
                await axios.get("http://mdvr.attg.cc/StandardApiAction_login.action?account=Admin&password=@Andaman789",{}  ).then(async (res)=>{
                    serJsessionId(res.data.JSESSIONID)
                    // getDevicesMDVR(res.data.JSESSIONID)
                    findSimAll(res.data.JSESSIONID)
                    
                })
            }else{
                // getDevicesMDVR(JSESSIONID)
                findSimAll(JSESSIONID)
            }
        }
    }

    const changeAccordion=(id:any)=>{
        setAccordion(id)
        console.log(id)
        if(id){ 
            axios.get("http://mdvr.attg.cc/StandardApiAction_getDeviceOlStatus.action?jsession="+JSESSIONID+"&devIdno="+id).then(res =>{
                console.log("res ",res.data)
                setDeviceOnline(res.data.onlines)
            })
        }
    }

    const vehicleAccordion=()=>{
        setVehicleNo(accordion)
    }
    const searchMdvrVehicle=(word:any)=>{
        console.log("searchMdvrVehicle ",word)
        if(word.length > 2){ 
            const needle = word.toLowerCase() 
            const filterDevice = vehicleBackup.filter((e:any)=> e.nm.toLowerCase().indexOf(needle) > -1) 
            console.log("filterDevice ",filterDevice)
            dispatch(setMdvrVehicle(filterDevice))
        }else{
            dispatch(setMdvrVehicle(vehicleBackup))
        }
    }

    const scrollToiFrame=()=>{
        
        let realtimeIframe = document.getElementById("realtime-iframe")!
        realtimeIframe.scrollIntoView();

    }
    return( 
    <IonPage>
        {/* <IonHeader color='transparent' className='ion-no-border'   > 
        <IonToolbar color='transparent' mode='ios' className='ion-no-padding'>  
           
                <IonButtons slot="start"  className='ion-hide-md-up  '  >
                <IonButton size='small' onClick={()=>{history.goBack()}}> <IonIcon icon={chevronBackOutline} />  <IonText >Back</IonText> </IonButton>
                </IonButtons>
                <IonTitle style={{fontWeight:"bold",fontSize:"1.3em",marginLeft:"1rem" }} >{'Reports'}</IonTitle>
        </IonToolbar>
        </IonHeader> */}
        <IonContent fullscreen color={"light"} > <br/>
       <IonHeader color='transparent' className='ion-no-border'   > 
        <IonToolbar color='transparent' mode='ios' className='ion-no-padding'>  
            <NavBar />  
                <IonButtons slot="start"  className='ion-hide-md-up  '  >
                <IonButton size='small' onClick={()=>{history.goBack()}}> <IonIcon icon={chevronBackOutline} />  <IonText >Back</IonText> </IonButton>
                </IonButtons>
                <IonTitle style={{fontWeight:"bold",fontSize:"1.3em",marginLeft:"1rem" }} >{'Reports'}</IonTitle>
        </IonToolbar>
        </IonHeader>
        {/* <NavBar />   */}
            <Tabs className={"home-screen-tabs"} >
                <TabList>
                    <Tab> Realtime </Tab>
                    <Tab> Sim </Tab>
                </TabList>
             <TabPanel>  
                <div className="mdvr-content" >
                <IonGrid >
                    <IonRow>
                    <IonCol> 
                        <div  className="playback-content"  >
                       
                        <div   style={{display:'flex', flexDirection:"row", justifyContent:"space-between" ,marginBottom:"2rem"}}  >
                            <IonRow style={{width:"100%"}}>
                                <IonCol sizeXs="12" sizeSm="12" sizeMd="6" >
                                    <div  >
                                        <IonIcon icon={searchCircle} onClick={()=>{vehicleAccordion()}}  />
                                        <IonLabel> ค้นหาด้วยทะเบียนที่เลือก : { JSESSIONID&&vehicleno &&<strong>{vehiclePlate} | CH {chnDisplay.split(',').map((e) => <span>{Number(e)+1} </span>)}</strong>} </IonLabel>
                                    </div>
                                </IonCol>
                                <IonCol sizeXs="12" sizeSm="12" sizeMd="6" >
                                    <div  style={{display:'flex', flexDirection:"row",justifyContent:"flex-end"}}>
                                    {chanelLayout.map((e,index)=> 
                                        <IonImg  
                                        onClick={()=>{setLayout(e.layout)}}
                                        key={index} 
                                        src={layout===e.layout ? e.iconSelect: e.icon} style={{width:"1.5rem",margin:"0 .5rem"}} 
                                        /> 
                                    )} </div>
                                 </IonCol>
                            </IonRow>
                          
                        </div>
                        { JSESSIONID && <div >  
                            <iframe    id="realtime-iframe"
                                style={{}}
                                allowFullScreen={true}  
                                src={vehicleno?`http://mdvr.attg.cc/808gps/open/player/video.html?lang=en&devIdno=${vehicleno}&jsession=${JSESSIONID}&channel=${layout}&chns=${chnDisplay}` :"http://portal.attg.cc/pageNotFound/?text=Player Not Start!"} 
                            > 
                            </iframe> 
                           {layout === 1 && <div>
                                {deviceMdvr?.chnName.split(",").map((ch:any,i:any)=>
                                    <IonButton 
                                        color={"primary"}
                                        fill={chnDisplay.indexOf(String(i)) > -1 ? "outline": "clear"} 
                                        size="small" 
                                        onClick={()=>{setChanDisplay(String(i))}} 
                                    >  { ch } 
                                    </IonButton>
                                )}
                            </div>  }
                        </div> } 
                        </div>
                        </IonCol>
                        <IonCol sizeSm="12" sizeMd="4" sizeLg="3"  sizeXl="3" >
                        <div  className="playback-content" style={{overflowY:"scroll"}} >
                            <IonLabel className="ion-margin" style={{fontWeight:"bold"}} >Realtime Mdvr</IonLabel>
                            <IonSearchbar mode="ios" value={vehiclePlate} onIonInput={(e)=>{setVehiclePlate(e.detail.value!);searchMdvrVehicle(e.detail.value)}} ></IonSearchbar><br/> 
                            {JSESSIONID &&  
                                <VehiclesSelect 
                                    accordion={accordion} 
                                    changeAccordion={(e:any)=>{changeAccordion(e)}} 
                                    setVehiclePlate={(e:any)=>{ setVehiclePlate(e)}} 
                                    setVehicleNo={(e:any)=>{ setVehicleNo(e);scrollToiFrame();console.log("vehicleNo ",e)}} 
                                    setChannel={(e:any)=>{ setChn(e);scrollToiFrame(); }} 
                                    setChanDisplay={(e:any)=>{ setChanDisplay(e);scrollToiFrame();console.log("chnstr ",e)}}
                                    chnDisplay={chnDisplay}
                                    JSESSIONID={JSESSIONID} 
                                    setDevice={(e:DeviceMdvr)=>{setDeviceMdvr(e); console.log("setDevice ",e)}}
                                />
                            }
                        </div>
                        </IonCol>
                       
                    </IonRow>
                </IonGrid>
            </div>

            </TabPanel> 
            <TabPanel>
                <IonGrid>
                    <IonRow>
                        <IonCol size="4" >
                            <IonList  style={{height:"80vh",overflowY:"scroll"}} >
                            {
                                sims.map((e:any,index:any)=> 
                                    <IonItem key={index} onClick={()=>{findSim(JSESSIONID, e.id)}}>
                                        <IonIcon icon={checkmarkCircle} color={e?.status === 1 ?"success":"danger"} />
                                        <IonLabel>{e.cardNum}</IonLabel>
                                    </IonItem>
                                )
                            }
                            </IonList> 
                        </IonCol>
                        <IonCol size="4" >
                            <h4>sim str</h4>
                                <IonLabel>{simstr}</IonLabel>
                        </IonCol>
                    </IonRow>
                </IonGrid>
            </TabPanel>
        </Tabs>
        </IonContent>
    </IonPage>)
}
export default RealtimeMdvr;


 

export const VehiclesSelect=({accordion,changeAccordion,setVehiclePlate, JSESSIONID , setChannel ,setVehicleNo , setChanDisplay , chnDisplay , setDevice}:any)=>{
    const [devices,setDevices] = useState<any[]>(devicemock)
    const vehicleMdvr = useSelector(getMdvrVehicles)
    const vehicleBackup = useSelector(getMdvrVehiclesBackup)
    const [chns,setChns] = useState([0,1,2,3])
    const dispatch = useDispatch()
    const [open , setOpen] = useState(false)

    useEffect(()=>{ 
        if(JSESSIONID){ 
            getDevicesMDVR(JSESSIONID)
        }  
        console.log("vehicleMdvr ",vehicleMdvr)
    },[ ])

    const getDevicesMDVR=async(session:any)=>{ 
        let devices = await axios.get(`http://mdvr.attg.cc/StandardApiAction_queryUserVehicle.action?jsession=${session}&language=en`,{}).then((res)=>{return res.data })
       
        console.log("devices ",devices)
        let vehicleWithOnline = await devices?.vehicles.map((e:any)=>{
            return {...e ,...{isOnline:0}}
        })
        dispatch(setMdvrVehicle(vehicleWithOnline))
        dispatch(setMdvrVehicleBackup(vehicleWithOnline))
        await setDevices(vehicleWithOnline)
        updateOnlineDevice(vehicleWithOnline)
    }

    const updateOnlineDevice=async(vehicle:any[])=>{
        let dWithOnline:any=[]
        await  vehicle.map(async(d:any)=>{ 
            let online =   await axios.get("http://mdvr.attg.cc/StandardApiAction_getDeviceOlStatus.action?jsession="+JSESSIONID+"&devIdno="+d.dl[0].id)
            .then(res =>{ 
              return  res.data.onlines[0].online
            })       
            try{
                let update = {...d , ...{ isOnline: online }}
                dWithOnline.push(update) 
            }catch(err){
                console.log("err " ,err)
            }
        })
        setTimeout(()=>{   
            dispatch(setMdvrVehicle(dWithOnline))
            dispatch(setMdvrVehicleBackup(dWithOnline))
            // setDevices(dWithOnline)
        },3000) 
    }

    const isCheck=(index:any)=>{ 
        console.log("isCheck chnDisplay ",chnDisplay)
        let chns = chnDisplay.split(",") 
       return chns.includes(String(index))
    }

    const changeCheckbox=async (checked:any , index:any)=>{
        let lstcheckd = chns
        let update: any[] | ((prevState: number[]) => number[]) =[]
        if(!checked && chns.includes(index) === true){
            update = lstcheckd.filter((e)=> e != index)
        }
        if(checked &&  chns.includes(index) === false){
            update = lstcheckd
            update.push(index)
        }
        setChns(update)
        let chnstr = ""
        await update.map((ch:any , i:any)=>{ 
            chnstr= chnstr+ch+ ","
        })
        chnstr = chnstr.substring(0 , chnstr.length-1) 
        return setChanDisplay(chnstr)
    }

    return( 
    <div style={{width:"100%"}} >
        {
            vehicleMdvr?.map((device:any,i:any)=> 
            <div   key={i}  aria-details={device.dl[0].id} style={{width:"100%"}}>
                <IonItem   >
                    <IonLabel className="ion-text-wrap " >
                            {device?.isOnline != undefined && 
                            <IonIcon 
                                style={{fontSize:".7em"}} 
                                icon={ellipse} 
                                color={device?.isOnline === 1 ?"success":"medium"} 
                            /> } &nbsp;
                            <IonText>{device.nm}</IonText>   
                    </IonLabel>
                    <IonButtons slot="end"  > 
                        {device.dl[0].id === accordion  ? 
                        <IonButton 
                            fill="clear" size="small" mode="ios" color={"primary"}  
                            onClick={()=>{
                                setOpen(!open);setVehicleNo(null);
                                changeAccordion(null);
                                setVehiclePlate(null)}
                            }
                        > 
                            <IonIcon  icon={chevronUpCircle }  style={{fontSize:"1.1em"}} /> 
                        </IonButton> :
                        <IonButton 
                            fill="clear" size="small" mode="ios"  
                            onClick={()=>{
                                setOpen(!open);
                                setDevice(device); 
                                changeAccordion(device.dl[0].id); 
                                setVehiclePlate(device.nm);
                                setVehicleNo(device.dl[0].id)}} 
                        >
                            <IonIcon  icon={chevronDown}  style={{fontSize:".9em"}} /> 
                        </IonButton> }
                    </IonButtons>
                    
                </IonItem>
                {device.dl[0].id === accordion  && 
                <div className="device-channel"   >
                 { device?.chnName?.split((",")).map((e:any,ich:any)=>  
                    <IonItem key={ich} onClick={()=>{ setChannel(ich)}} className="channel-item">
                        <IonCheckbox 
                         checked={isCheck(ich)} 
                         onIonChange={(e)=>{ console.log("change chn ",e.detail.checked) ;changeCheckbox(e.detail.checked , ich)}}
                        ></IonCheckbox> &nbsp;
                        <IonLabel>{e}</IonLabel>
                    </IonItem> 
                 )}
                </div>}
            </div>
            )
        }
    </div>
    )
}
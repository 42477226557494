import { IonButton, IonCol, IonContent, IonGrid, IonIcon, IonLabel, IonRoute, IonRow, IonText } from "@ionic/react"
import { printOutline } from "ionicons/icons";
import { TableDeviceInOut } from "../ReportComponent/ReportTable";
import moment from "moment";
import { pdfMake } from "../../../lib/pdfMake"

const DevicesInOut=({ table ,details,orientation}:any)=>{

    const printReport=()=>{ 
        let bodyTable = []
        let headers = table.headers.map((el:any)=>{ 
            return  {text: el.label.toUpperCase()  , alignment:'center', bold:true  } 
       }) 
        table.rowsData.map((rows:any)=>{  
            let row =  rows.cols.map((cols:any)=>{  
                return  {text: cols.label , alignment:'center' }   
            })
            bodyTable.push(row)
        })  
        bodyTable.unshift(headers)
        console.log("bodyTable ",bodyTable)

        pdfMake.createPdf({
            pageOrientation: orientation ,
            content: [ 
                {text: details?.reportName , fontSize: 16 , bold:true  , alignment:'center', marginBottom: 5},
                {text:  `ทะเบียน  ${details?.name}`, fontSize: 14  , alignment:'center'},
                {text: `วันที่ ${moment(details?.start).format("DD MMM YYYY HH:ss")} - ${moment(details?.end).format("DD MMM YYYY HH:ss")}` , alignment:'center', fontSize: 14 ,   marginBottom: 15},
                {   
                    layout: 'lightHorizontalLines',
                    marginTop: 5 ,
                    table:{
                      dontBreakRows:true,
                      headerRows: 1,
                    //   { colSize: 1 ,label: "#",id: "#"  },  
                    //   { colSize: colsSize  ,label: "vehicle" ,id: "vehicle"  },   
                    //   { colSize: colsSize  ,label: "status" ,id: "status"  },   
                    //   { colSize: colsSize  ,label: "address" ,id: "address"  },  
                    //   { colSize: colsSize  ,label: "mileage" ,id: "mileage"  },   
                    //   { colSize: colsSize  ,label: "time" ,id: "time"  },   
                      widths:[ "5%","*","*","*","*","*" ],
                      body: bodyTable  
                    }
                  } 
            ], 
            defaultStyle: {
                font: "THSarabunNew",
            },
            header: function (currentPage, pageCount) {
                return {
                    columns: [
                        { 
                            text: details.reportName,
                            fontSize: 10 ,
                            marginLeft: 16,
                            margin: [20, 20, 50, 20] 
                        } ,
                        { text: "Page " + currentPage.toString() + ' of ' + pageCount, alignment: 'right', style: 'normalText', margin: [0, 20, 50, 0] }
                    ]
                }
        },
            footer: {
                columns: [
                    { 
                        text:"บริษัท อันดามันแทร็คกิ้ง จำกัด | Andaman Tracking .Co.Ltd  @Generate on  tracking.andamantracking.com" ,
                        fontSize: 9 ,
                        marginLeft: 15
                    } 
                ]
            },
        }).open()
    }

return(
    <div style={{width:"100%", height:"70vh"}} >
    <IonButton  
        onClick={()=>{printReport()}}
        mode="ios" fill="clear" 
        style={{position:"relative", top:"1rem", right:"1rem"}} 
    >
        <IonLabel  style={{fontSize:".8em"}}>Print</IonLabel>  &nbsp;
        <IonIcon icon={printOutline}  /> 
    </IonButton>
    <IonGrid id="report-content" >
        <IonRow> 
            <IonCol size="12" >
                <IonLabel style={{fontSize:"1.2em",fontWeight:"bold"}} >{details.reportName}</IonLabel><br/>
                <IonLabel style={{fontSize:'.8em'}}>
                <IonText>วันที่ {moment(details.start).format("DD MMM YYYY HH:mm")}</IonText>
                <IonText>-</IonText>
                <IonText>วันที่ {moment(details.end).format("DD MMM YYYY HH:mm")}</IonText>
                </IonLabel>
            </IonCol>
        </IonRow><br/> 
        <IonRow>
            <IonCol size="12" >
               <TableDeviceInOut headers={table.headers} rowsData={table.rowsData} />
            </IonCol>
        </IonRow>
    </IonGrid>
    </div> 
)
}
export default DevicesInOut;
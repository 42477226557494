import { IonButton, IonCol, IonContent, IonGrid, IonIcon, IonLabel, IonRow, IonText, IonTitle } from "@ionic/react" 
import { printOutline  } from "ionicons/icons"
import moment from "moment"
import { TableMinuteReport } from "./ReportComponent/ReportTable"
import { configPDF, pdfMake } from "../../lib/pdfMake" 


const ReportMinuteDaily=({details,table,maxSpeed}:any)=>{ 

    const printReport=()=>{
        let bodyTable:any = []  
        let maxSpeedTable:any = []
        let headers = table.header.map((el:any)=>{ 
             return  {text: el.label.toUpperCase()  , alignment:'center', bold:true  } 
        })
        table.rowData.map((rows:any)=>{  
            let row =  rows.cols.map((cols:any)=>{  
                return  cols.id === "key" ? {text: cols.label?"กุญแจเปิด":"กุญแจปิด" , alignment:'center' } :
                cols.id === "status" ? {text:  Number(cols.label) === 7 ?"Move" : Number(cols.label) === 24 ? "IDLE" :  Number(cols.label)===23?"STOP":"-"  , alignment:'center' } 
                :{text: cols.label , alignment:'center' }   
            })
            bodyTable.push(row)
        })  
        bodyTable.unshift(headers)

        maxSpeed.map((rows:any)=>{  
            let row =  rows.cols.map((cols:any)=>{  
                return  {text: cols.label , alignment:'left' ,bold: cols.id.search("title")>-1 ? true:false}   
            })
            maxSpeedTable.push(row)
        })  

        pdfMake.createPdf({ 
            ...configPDF ,
            pageOrientation: 'landscape',
            content:[  
                {text: details.reportName , fontSize: 16 , bold:true  , alignment:'center', marginBottom: 15},
                {text:`วันที่ ${moment(details.start).format("DD MMMM   HH:mm")} - ${moment(details.end).format(" DD MMMM  HH:mm")} `, alignment:'center'},
                {text:`ทะเบียนรถ  ${details?.name} `, alignment:'center'}, 
                {text:"Overview ", bold:true ,fontSize: 14 },
                {   
                    layout: 'noBorders',
                    marginTop: 10 ,
                    fontSize: 12 ,
                    table:{
                      dontBreakRows:true,
                      headerRows: 1,
                      widths: ["auto","auto"],
                      body: maxSpeedTable  
                    }
                }  ,
                {text:" ", bold:true },
                {   
                    layout: 'lightHorizontalLines',
                    marginTop: 10 ,
                    table:{
                      dontBreakRows:true,
                      headerRows: 1,
                      widths: headers.map((e:any)=>{return  e =="address" ? 400: "auto"}),
                      body: bodyTable  
                    }
                }
            ],
            defaultStyle: {
                font: "THSarabunNew",
                fontSize: 10
            },
            header: function (currentPage, pageCount) {
                return {
                    columns: [ 
                        { 
                            flexDirection:"row",
                            ul: [
                            {  image: "icon" , width: 10 , margin: [20, 20, 50, 20]   },
                            'รายงาระยะทางรายวัน' , 
                        ] },
                        { text: "Page " + currentPage.toString() + ' of ' + pageCount, alignment: 'right', style: 'normalText', margin: [0, 20, 50, 0] }
                    ]
                }
            },
        }).open()
    }
    
    return(
    <div>
        <IonButton  
            onClick={()=>{printReport()}}
            mode="ios" fill="clear" 
            style={{position:"relative", top:"1rem", right:"1rem"}} 
        >
            <IonLabel  style={{fontSize:".8em"}}>Print</IonLabel>  &nbsp;
            <IonIcon icon={printOutline}  /> 
        </IonButton><br/>
        <IonRow> 
                <IonCol size="12" className="ion-padding" >
                    <IonLabel style={{fontSize:"1.2em",fontWeight:"bold"}} >{details?.reportName}</IonLabel><br/>
                    <IonLabel style={{fontSize:'.8em'}}>
                        <IonText>วันที่ {moment(details.start).format("DD MMM YYYY HH:mm")}</IonText>
                        <IonText>-</IonText>
                        <IonText>วันที่ {moment(details.end).format("DD MMM YYYY HH:mm")}</IonText>
                    </IonLabel>
                </IonCol>
                <IonCol size="6" >
                
                <IonGrid style={{border:"1px solid #DDD"}} >
                    <IonRow style={{backgroundColor: "#DDD" }} >
                        <IonCol size="12" className="ion-text-center" >
                            <IonLabel><strong>สรุปข้อมูล</strong></IonLabel> 
                        </IonCol>
                    </IonRow>
                    {  maxSpeed.map((row:any,index:any)=>
                        <IonRow key={index} style={{backgroundColor: index%2>0 ?"#EEE":"#FFF"}} >
                            {
                                row.cols.map((col:any,i:any)=>
                                  <IonCol size="6" key={i} >  
                                  {col.id === "time" ? 
                                    <IonLabel style={{fontSize:".8em"}} >{moment(col.label).format("HH:mm DD MMM YYYY")}</IonLabel> :
                                    <IonLabel style={{fontSize:".8em"}} >{col.label}</IonLabel>  
                                  } 
                                  </IonCol>  
                                )
                            }
                        </IonRow>
                    )}
                </IonGrid><br/>
            </IonCol>
        </IonRow> 
        <IonRow   >
           
            <IonCol size="12" >
                <TableMinuteReport headers={table.header} rowsData={table.rowData} />
            </IonCol>
        </IonRow>
    </div>
    )
}

export default ReportMinuteDaily;
import { IonButton, IonCol, IonContent, IonGrid, IonIcon, IonLabel, IonRow, IonText } from "@ionic/react"
import { TableEvents, TableOverview } from "./ReportComponent/ReportTable";
import moment from "moment";
import { printOutline } from "ionicons/icons";
import { pdfMake } from "../../lib/pdfMake" 

const RepEventStop=({ table ,details ,orientation}:any)=>{
    const caridle = "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAMAAAAoLQ9TAAAAV1BMVEUAAAD/AAD/QID/R2L/SmD/S2H/SGL/R2D/SWD/SGL/SmH/SWD/SWH/SWL/SGH/SWL/SWH/SGD/SmL/SWH/SWL/SWH/SWH/SWH/SWH/SWH/SWH/SWH////sPTM/AAAAG3RSTlMAAQQvMDo8PU1OYWJpcHGIiZSVw8TV4uXp6vF3wUHrAAAAAWJLR0QcnARBBwAAAF9JREFUGBltwQkOgjAAAMEFpXigqFzK/v+fYtM02jjDP9fZaL4Qnc1OfEx2FZuqc2Kz91UT1U93wM07ycMejqtf1gOLPxa0CSZNUDS0JqFVLGABtc9UVDKV0YFsdKD0BrDpDxg9YcGnAAAAAElFTkSuQmCC"

    const printReport=async()=>{ 
        console.log("table ",table)
        let bodyTable:any = [] 
        let headers =await table.header.map((el:any)=>{
             return  {text: el.label  , alignment:'center', bold:true  } 
        })
        table.rowData.map((rows:any)=>{  
            let row =  rows.cols.map((cols:any)=>{
                console.log("cols ",cols)
                return   cols.id === "status" ? {image:"ceridle" ,width: 15 , margin: 5} : {text: cols.label  , alignment:'center' } 
            })
            bodyTable.push(row)
        }) 
        
        bodyTable.unshift(headers)


        pdfMake.createPdf({ 
            pageOrientation: orientation,
            images: {
                ceridle: caridle
            },
            content:[  
                {text: details.reportName, fontSize: 16 , bold:true  , alignment:'center', marginBottom: 15},
                {text:`วันที่ ${moment(details.start).format("DD MMMM   HH:mm")} - ${moment(details.end).format(" DD MMMM  HH:mm")} `, alignment:'center'},
                {text:`ทะเบียนรถ  ${details?.name} `, alignment:'center'}, 
                 
                {   
                    layout: 'lightHorizontalLines',
                    marginTop: 10 ,
                    table:{
                      dontBreakRows:true,
                      headerRows: 1,
                      widths:  headers.map((e:any)=>{return  e =="address" ? 300: "auto"}),
                      body: bodyTable  
                    }
                  } 
            ],
            defaultStyle: {
                font: "THSarabunNew",
            }
        }).open() 
    }

    return(<div className="ion-padding" >    
        <IonButton  
            onClick={()=>{printReport()}}
            mode="ios" fill="clear" 
            style={{position:"relative", top:"1rem", right:"1rem"}} 
        >
            <IonLabel  style={{fontSize:".8em"}}>Print</IonLabel>  &nbsp;
            <IonIcon icon={printOutline}  /> 
        </IonButton>
        <IonGrid>
            <IonRow> 
                <IonCol size="12" >
                    <IonLabel style={{fontSize:"1.2em",fontWeight:"bold"}} >{details.reportName}</IonLabel><br/>
                    <IonLabel style={{fontSize:'.8em'}}>
                        <IonText>วันที่ {moment(details.start).format("DD MMM YYYY HH:mm")}</IonText>
                        <IonText>-</IonText>
                        <IonText>วันที่ {moment(details.end).format("DD MMM YYYY HH:mm")}</IonText>
                    </IonLabel>
                </IonCol>
            </IonRow><br/> 

            <IonRow>  
                <IonCol size="12" >  
                   <TableEvents headers={table.header} rowsData={table.rowData} />
                </IonCol>
            </IonRow>
        </IonGrid>
    </div>)
}

export default RepEventStop;
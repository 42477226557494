import { IonButton,IonCol, IonContent, IonGrid, IonHeader, IonIcon, IonLabel,IonPage,IonRow, IonSegment, IonSegmentButton, IonText } from "@ionic/react";
import { SelectPopover, Tables } from "../components/AppComponent";
import { useSelector } from "react-redux";
import { getBackup, getDeviceParams, getDevices } from "../store/appSlice";
import { useEffect, useState } from "react";
import "./css/DrivingPoints.css"
import { chevronBack, closeCircle, closeCircleOutline, search, star } from "ionicons/icons";
import { Swiper, SwiperSlide } from "swiper/react";
import { useHistory, useParams } from "react-router"; 
import { getBehaviorDevice } from "../actions";
import { DateEndReport, DateRangPopover, DateStartReport } from "../components/ReportComponent";
import moment from "moment";
var SHA1 = require("crypto-js/sha1");
var CryptoJS = require("crypto-js");

let evetsTable= {
    header : [ 
        { id:'eventname' ,  label:"Event Name" ,show: true , map: false} ,
        { id:'time',        label:"Time" ,show: true , map: false} ,
        { id:'location' ,   label:"Location" ,show: true , map:true} , 
        { id:'driver'   ,   label:"Driver" ,show: true , map: false} ,  
    ],
    detail: {
      active: 0 ,
      inactive:0 ,
      all: 1,
      page: 1 ,
      canAdd: false,
      role:"devices-point",
      righttable:false
    },
    body: [
      {
          id: 215 ,
          show: true ,
         
          column: [
              { id:'eventname' , label:"เบรกกะทันหัน"  },
              { id:'time' , label:"08:20:01 03/10/23" }, 
              { id:'location' , label:"ณ  ( 54.27 m) 4103   ต.นาเคียน อ.เมืองนครศรีธรรมราช  จ.นครศรีธรรมราช 80000" , map:true,  position:{ lat: 9.4514475,  lng: 7.6541474 }},
              { id:'driver' , label: "MR. CHANUWAT THONGBUT" } 
          ]
      },
      {
            id: 216 ,
            show: true ,
            map:true,
            position:{ lat: 9.4514475,  lng: 7.6541474 },
            column: [
                { id:'eventname' , label:"เบรกกะทันหัน" },
                { id:'time' , label:"08:20:01 03/10/23" }, 
                { id:'location' , label:"ณ  ( 54.27 m) 4103   ต.นาเคียน อ.เมืองนครศรีธรรมราช  จ.นครศรีธรรมราช 80000" , map:true,  position:{ lat: 9.4514475,  lng: 7.6541474 }},
                { id:'driver' , label: "MR. CHANUWAT THONGBUT" } 
            ]
        }
    ]
} 


let devicePoint= {
    point: 3.25 ,
    vehicle: "Accord-1345 *เทส*",
    behaviorGrouped :[
        {
            name:"เบรกกะทันหัน" ,
            event_point_removed: 3 ,
            event : evetsTable
        },
        {
            name:"เร่งกะทันหัน" ,
            event_point_removed: 1 ,
            event: evetsTable

        },
        {
            name:"เลี้ยวกะทันหัน" ,
            event_point_removed: 1 ,
            event:  evetsTable
        },
        {
            name:"ความเร็วเกิน" ,
            event_point_removed: 0 ,
            event: evetsTable
        }
    ]
}

// {
//     "id": "6586936ee4b08ca456b96c8d",
//     "deviceId": 61020,
//     "code": "91",
//     "codeName": "Sharp Turn to Right",
//     "deviceBrand": "MEITRACK",
//     "eventTime": 1703318380000,
//     "eventTimeStr": "Sat Dec 23 14:59:40 ICT 2023",
//     "lat": 15.314443,
//     "lon": 105.032793,
//     "address": "( 677.54 m) วัดบ้านแคน  ต.ดอนมดแดง อ.ดอนมดแดง  จ.อุบลราชธานี 34000",
//     "driverId": "",
//     "driverName": "",
//     "speed": 46
// }
const tableHeader = [
    { id:'eventname' ,  label:"Event Name" ,show: true , map: false},
    { id:'deviceBrand' ,   label:"Device Brand" ,show: true , map: false  },
    { id:'eventTime' ,  label:"Event Time" ,show: true , map: false},
    { id:'address' ,  label:"Address" ,show: true , map: false}, 
    { id:'speed' ,  label:"Speed" ,show: true , map: false }, 
    { id:'driverName' ,  label:"Driver Name" ,show: true , map: false }, 
]
const BeahaviorDriving =()=>{
    const history = useHistory()
    const devices = useSelector(getDevices)
    const [device,setDevice] = useState({value:0,label:"Select"})
    const [segment,setSegment] = useState("เบรกกะทันหัน")
    const [swiper ,setSwiper] = useState(null) 
    const deviceParams = useSelector(getDeviceParams)
    const backup = useSelector(getBackup)
    const [showContent,setShowContent] = useState(false)
    const [datestart,setDateStart]= useState(moment().format("YYYY-MM-DDT00:00:00"))
    const [dateend,setDateEnd]= useState(moment().format("YYYY-MM-DDT23:59:59"))
    const [openDate, setOpenDate] = useState(false)
    const [behaviortable,setBehavioirTable ]= useState([{ 
        show: true ,
        map:true,
        position:{ lat: 9.4514475,  lng: 7.6541474 },
        column: [
            { id:'eventname' , label:"เบรกกะทันหัน" },
            { id:'time' , label:"08:20:01 03/10/23" }, 
            { id:'location' , label:"ณ  ( 54.27 m) 4103   ต.นาเคียน อ.เมืองนครศรีธรรมราช  จ.นครศรีธรรมราช 80000" , map:true,  position:{ lat: 9.4514475,  lng: 7.6541474 }},
            { id:'driver' , label: "MR. CHANUWAT THONGBUT" } 
        ]
    }])

    const changeSegment=(type:any)=>{
        setSegment(type)
        runnerTable(type)
    }

    const [behaviors,setBehaviors] = useState<any>()

    const searchEventPoint=async()=>{
        let res = await getBehaviorDevice({
            deviceId: device?.value ,
            start: new Date(datestart).getTime() ,
            end: new Date(dateend).getTime()
        })  
        console.log("searchEventPoint res ",res)
        setBehaviors(res)
        setShowContent(true) 
        runnerTable(segment) 
    }

    const runnerTable=async(type:any)=>{ 
        let behaviorType:any = behaviors?.behaviorGrouped.find((e:any)=> e.name.search(type) > -1 )
        if(behaviorType){
        let table :any =[]
        await  behaviorType?.event.map((e:any,i:any)=>{
            table.push ({
                show: true ,
                map:true,
                position:{ lat: e.lat ,  lng: e.lng },
                column: [
                    { id:'eventname' , label: type },
                    { id:'deviceBrand' , label: e.codeName  },
                    { id:'eventTime' , label: e.eventTime },
                    { id:'address' , label: e.address }, 
                    { id:'speed' , label: e.speed  }, 
                    { id:'driverName' , label: e.driverName  }, 
                ]     
            })   
        })
        setBehavioirTable(table) 
        }
    }

    const searchFromParam=async(id:any)=>{ 
        console.log("deviceid ", id) 

        let device = await backup.find((el:any)=> Number(el.device_id) === Number(id)) 
        console.log("find device fron params ",device)
        if(device){ 
            setDevice({ value : device.device_id ,  label: device.name }) 

            searchEventPoint()
        }else{
            console.log("select fail ",device)
        }
    }

    const clearSearch=()=>{
        setDevice({value:0,label:"Select"})
        setShowContent(false)
    }

    useEffect(()=>{ 
        setBehavioirTable([])
       if(deviceParams){ 
         searchFromParam(deviceParams)
       } 


       return(()=>{
        clearContent()
       })
    },[ ])

    const clearContent=()=>{
        setDevice({value:0,label:"Select"})
        setBehavioirTable([])
        setShowContent(false)
    }

    return(
    <IonPage> 
    {/* <AuthCheck />   */}
        <IonContent className=" backgroung-tint" >
            
            <IonGrid className="backgroung-tint" style={{height:"98vh"}}>
                <IonRow>
                    <IonCol sizeXs="12" sizeSm="12" sizeMd="4" sizeLg="3.5" className="ion-padding" >
                        <div className="drivingpoint-form" >
                            <IonLabel style={{padding:"0px"}} > 
                             <h1 style={{fontWeight:"900"}}>พฤติกรรมการขับขี่</h1>
                            </IonLabel><br/><br/>
                            <IonRow>
                                <IonCol  >
                                    <SelectPopover 
                                        id={'devices'}
                                        contents={devices.map((e:any)=>{ return {value: e.device_id, label: e.name} })} 
                                        title={'Devices'} 
                                        subtitle={"select device."}  
                                        selected={(e:any)=>{return setDevice(e)}}
                                        current={device} 
                                        addstyle={{marginTop:".5rem"}}
                                        position={{
                                            side:"right",
                                            alignment:"start"
                                        }}
                                    />   
                                </IonCol>
                                <IonCol size="12" style={{position:"relative"}} >
                                    <div className="input" >
                                      <DateStartReport date={datestart} openDate={setOpenDate} bg={false} showtime={false} />  
                                    </div>
                                    <div className="input" >
                                      <DateEndReport date={dateend} openDate={setOpenDate} bg={false}  showtime={false} />
                                    </div>
                                    <DateRangPopover 
                                        start={datestart} 
                                        setStart={setDateStart} end={dateend}
                                        setEnd={setDateEnd} openDate={setOpenDate} 
                                        isOpen={openDate} 
                                    />
                                </IonCol>
                                {device.value > 0 &&<IonCol size="12" className="set-center" onClick={()=>{clearSearch()}} >
                                        <IonButton fill="clear" size="small" mode="ios"> 
                                            <IonLabel>{device.label}</IonLabel>
                                            <IonIcon icon={closeCircle} />
                                        </IonButton> 
                                </IonCol>}
                                <IonCol size="12" > 
                                    <IonButton  onClick={()=>{searchEventPoint() }} mode="ios" expand="block" color={"secondary"} >
                                        <IonLabel>Search</IonLabel>
                                    </IonButton> 
                                </IonCol>
                            </IonRow>
                        </div>
                    </IonCol>
                    
                    <IonCol  sizeXs="12" sizeSm="12" sizeMd="8" sizeLg="8.5" className="ion-padding">
                        <IonButton fill="clear" style={{position:"absolute" , right:"2rem", top:"2rem"}} onClick={()=>{setDevice({value:0,label:"Select"});setShowContent(false)}}  > <IonIcon icon={closeCircleOutline} />  </IonButton>
                       {showContent &&  <div className="drivingpoint-form" > 
                            <IonHeader mode="ios" className="ion-no-border" style={{display:'flex' ,flexDirection:"row", alignItems:'center'}}> 
                              <IonButton fill="clear" onClick={()=>{history.goBack(); clearContent() }}  > <IonIcon icon={chevronBack} /> </IonButton> 
                              <IonLabel style={{padding:"0px"}} > 
                                    <h1 style={{fontWeight:"900"}}> {device?.label} </h1>
                                    <IonText color={"warning"} ><h5> <IonIcon icon={star}  /> <IonText>{behaviors?.point} ดาว</IonText> </h5></IonText>
                                </IonLabel>
                            </IonHeader>
                          <br/><br/>
                            <IonGrid>
                                <IonRow>
                                    <IonCol size="12" > <IonLabel style={{fontWeight:"bold"}} >{"เหตุการณ์สำคัญที่ส่งผลต่อคะแนน"}</IonLabel> </IonCol>
                                    <IonCol size="6" >
                                        <IonSegment value={segment} scrollable className="segment-event-point" mode="ios" style={{width:"100%"}}  >
                                            {
                                                behaviors?.behaviorGrouped.map((eve:any,index:any)=>  //@ts-ignore
                                                    <IonSegmentButton value={eve.name} mode="ios" key={index} onClick={()=>{changeSegment(eve?.name.toString()); swiper?.slideTo(index)}}  >
                                                        <IonLabel>{eve.name}</IonLabel> 
                                                        <IonLabel className="point">{eve.event.length} ครั้ง</IonLabel>
                                                    </IonSegmentButton>
                                                )
                                            }
                                        </IonSegment> 
                                    </IonCol>
                                    <IonCol size="12" style={{padding:"0px"}} >
                                        <Swiper 
                                            slidesPerView={1} 
                                            allowTouchMove={false} 
                                            onSwiper={(swiper:any) => {setSwiper(swiper)}}
                                            className="swiper-event-point"
                                        > 
                                                <SwiperSlide   style={{overflowX:"scroll"}} >
                                                    <Tables 
                                                        title={segment} 
                                                        description={""} 
                                                        headers={tableHeader} rows={behaviortable} 
                                                        tableHead={{
                                                            active: 0 ,
                                                            inactive:0 ,
                                                            all: 1,
                                                            page: 1 ,
                                                            canAdd: false,
                                                            role:"devices-point",
                                                            righttable:false
                                                          }}
                                                        role="devices-point"
                                                        current={1}
                                                        tablestyle=""
                                                        // nextPage={(e:any)=>{return nextPageUser(e)}} 
                                                    />
                                                </SwiperSlide>
                                             
                                            </Swiper>
                                    </IonCol>
                                </IonRow>
                            </IonGrid>
                        </div>}
                    </IonCol>
                </IonRow>
            </IonGrid>
        </IonContent>
    </IonPage>
    )
}
export default BeahaviorDriving;
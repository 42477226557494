import { IonButton, IonButtons, IonCheckbox, IonChip, IonCol, IonContent, IonGrid, IonHeader, IonIcon, IonLabel, IonModal, IonPage, IonPopover, IonRange, IonRow, IonSegment, IonSegmentButton, IonSpinner, IonText, IonTitle, IonToolbar, useIonAlert } from "@ionic/react"
import { alert, alertCircle, chevronBackOutline, closeCircle, closeCircleOutline, cogSharp, expand, eyeOutline, listOutline, pause, play, stop } from "ionicons/icons";
import NavBar from "../components/NavBar";
import { useHistory } from "react-router";
import { setBackupDevices, setDevices, setLoading } from "../store/appSlice";
import { useState, useEffect, useRef, act } from "react";
import { allDevices, getBehaviorDevice, passRoute } from "../actions";
import { DropDownDeviceMultiple } from "../components/AppComponent";
import { useDispatch, useSelector } from "react-redux";
import { DateStartReport, DateEndReport, DateRangPopover } from "../components/ReportComponent";
import moment from "moment";
import { FeatureGroup, MapContainer, Marker, Polyline } from "react-leaflet";
import { LayersControlMap } from "../leaflet/LeafletLayers";
import { DivIcon, Icon, map, Map as MapType } from "leaflet";
import { markerStart, markerStop } from "../leaflet/PublicMarker";
import "./css/GroupRouteHistory.css"
import { MapPassroute, PassRoutePolyLine, speeds } from "./PassrouteMap";
import { getPassRoute, getPassRouteIndex, getPassRouteInterval, getPlayAction, setPassRoute, setPassRouteIndex, setPassRouteInterval, setPlayAction } from "../store/mapSlice";
import { Route } from "./schema";
import L from "leaflet";
import { t } from "i18next";
import sha1 from "sha1" 
import { Tab, TabList, TabPanel, Tabs } from "react-tabs";
import ReactApexChart from "react-apexcharts"; 
// import "./css/"

let dSelect:any[] = []
const GroupRouteHistory=()=>{
    const history = useHistory()
    const [vehicleCheck,setVehicleCheck] = useState([{checked:false, device_id:0 ,value: 0,label:"Select",deviceIconId:1}])
    const [options,setOptions] = useState([])
    const [datestart,setDateStart] = useState(moment().format("YYYY-MM-DDT00:00").toString())
    const [dateend,setDateEnd] = useState(moment().format("YYYY-MM-DDT23:59").toString())  
    const [openDate,setOpenDate] = useState(false)  
    const [deviceRoute , setDeviceRoute] = useState<any[]>([])
    // const deviceRoute = useSelector(getDeviceRoute)
    const [spinner,setSpinner] = useState(false)
    const [dChecked,setDChecked] = useState<any[]>([])
    const [viewRoute,setViewRoute] = useState<any>(null)
    const [dialogView,setDialogView] = useState(false)
    const action = useSelector(getPlayAction)
    const passRouteIndex = useSelector(getPassRouteIndex) 
    const passroute:Route = useSelector(getPassRoute)   
    const [trackMarker,setTrackMarker] = useState(false)
    const passrouteinterval = useSelector(getPassRouteInterval)
    const [color,setColor] = useState("#134985")
    const [speed,setSpeed] = useState(speeds[0]) 
    const [ionalert ,dimissAlert] = useIonAlert()

    const dispatch = useDispatch()
    let mapref:MapType|any = null
    let playindex = 0 
    let routedraw: any[] = []  
    const playinterval = useRef<NodeJS.Timeout |any | null>(null); 
    let eventlocate:any= [0,0]
    let lastHeading = 0

    useEffect(()=>{   
        const getdevices=async()=>{
          setLoading(true)
          let result = await allDevices({}) 
          setLoading(false)
          await dispatch(setDevices(result))
          await dispatch(setBackupDevices(result)) 
          let options = result.map((e:any)=> {return {value: e.device_id ,label: e.name, ...e } })
          setOptions(options)   
          let optionscheck:any[] = result.map((e:any)=> {return {checked:false, value: e.device_id ,label: e.name, ...e } })
          optionscheck.unshift({checked:false, device_id:0 ,value: 0,label:"ทั้งหมด"})
          console.log("optionscheck ",optionscheck)
          setVehicleCheck(optionscheck) 
       } 
       getdevices() 
       
       return(()=>{
            mapref?.eachLayer(function(layer:any){
                console.log("layer ",   layer)
                if(layer?.editing){
                    mapref?.removeLayer(layer)
                }
            });
       })
      },[ ])

      const checkedVehicleOption=(index:any , val:any)=>{
        console.log("updateopts ",val)
        let updateopts = vehicleCheck
        if(val.value === 0 && val.checked){  
          updateopts = vehicleCheck.map((e )=>{
            return {...e,...{checked:true}}
          })  
        }else if(val.value === 0 && !val.checked){  
          updateopts = vehicleCheck.map((e )=>{
            return {...e,...{checked:false}}
          })  
        } else{
          setVehicleCheck(updateopts)
          updateopts.splice(index , 0)
          updateopts[index] = val 
        }
        setVehicleCheck(updateopts)
      }

      const serachDeviceRoutes=async ()=>{
        setDeviceRoute([]) 
        let did:any[] = []
        try{
            await vehicleCheck.map(  (e)=>{
                if(e.checked){
                    did = [...did , {id:e.value , name: e.label , deviceIcon: e.deviceIconId , deviceId: e.value }] 
                }
            })
           if(did.length > 0){
            setDChecked(did)
            setSpinner(true)
            console.log("did ",did)
            let routelists:any = []
            await did.map(async (val:any)=>{
                try{

                    let routelist = await passRoute({
                        deviceId: val.id,
                        start: new Date(datestart).getTime() ,
                        end: new Date(dateend).getTime() ,
                    }) 
                    console.log(val.id + " routelist ",routelist)
                    // routelists = [...routelists , {...routelist , val} ]
                    let bound:any = [] = await routelist?.list?.map(  (el:any) =>{
                        return [el.latitude ,el.longitude]
                    })
                    let routeid = await sha1(`start:${ new Date(datestart).getTime()},end:${new Date(dateend).getTime() },id:${val.id},create:${moment().format()}`)
                    // if(routelist.list.length >0){
                        //let listRoute:any = routelist.list
                        let newRoute:Route = {
                            ...routelist ,
                            _id : routeid , 
                            deviceId: val?.deviceId ,
                            name:  val?.name ,
                            route: bound, 
                            color: color,
                            show: true ,
                            distance_all: (Number(routelist.distance_all)/1000).toFixed(2),
                            deviceIcon: val?.deviceIcon ? val?.deviceIcon : 1 ,  
                            typeId:  1 , 
                        } 
                        dSelect = [...dSelect ,newRoute]
                    // }  
        
                    console.log("dSelect ",dSelect)
                    setDeviceRoute(dSelect) 
                    if(dSelect.length === did.length){
                        console.log("dSelect ",dSelect)
                        setSpinner(false) 
                    }
                }catch(err){
                    let routeid = await sha1(`start:${ new Date(datestart).getTime()},end:${new Date(dateend).getTime() },id:${val.id},create:${moment().format()}`)
                    let newRoute:Route | any  = {  
                        _id : routeid,
                        name:  val?.name ,
                        route: [], 
                        color: "",
                        show: true ,
                        distance_all:  "0" ,
                        deviceIcon: 1 ,  
                        typeId:  1 , 
                    } 
                    dSelect = [...dSelect ,newRoute]
                    console.log("serachDeviceRoutes err ",err)
                }
            })  

           
          }else{
            ionalert({
                mode:"ios" ,
                header: "Empty",
                message:"กรุณาเลือกรายการรถ !!" ,
                buttons: ["ตกลง"]
            })
          }
        }catch(err){ 
            setSpinner(false)
            console.log("err  ",err)
        }
        
      }
      
      async function closeWindow(index:any ,route:any){
        console.log("closeWindow  index ", index)
        console.log("closeWindow  route ", route)
        let remover = deviceRoute.filter((e:any)=> e != route )    
        let uncheck:any = []
        await vehicleCheck.map((e)=>{
            if(e.device_id === route?.deviceId ){
                uncheck = [...uncheck ,{...e , ...{checked:false}}]
            } else{
                uncheck = [...uncheck ,e]
            }
          
        }) 
        setVehicleCheck(uncheck) 
        console.log("closeWindow remover ",remover)
        setDeviceRoute(remover) 
      }

      const getPopupcontent=(content:any)=>{
        return `
           <div style={{width:"12rem"}} className="ion-padding" >
            <IonGrid>
                <IonRow> 
                    <IonCol size="12" >
                        <IonLabel><strong>Time:</strong> ${content?.event_stamp} </IonLabel> <br/>
                        <IonLabel><strong>address:</strong> ${content?.address} </IonLabel> <br/>
                        <IonLabel> <strong>speed: </strong> ${content?.speed} ${t("kmHUnit")}</IonLabel><br/>
                        <IonLabel>driver: ${content?.driver_id} ${content?.driver_message} </IonLabel><br/>
                    </IonCol>
                </IonRow>
            </IonGrid>
          </div> 
        `
    }
    const drawMarkerL=async (icon:any,listindex:any,position:any)=>{  
       if(markerroute){  mapref?.removeLayer(markerroute) }
       if(markerstatus){  mapref?.removeLayer(markerstatus) }
       if(routeplyline){  mapref?.removeLayer(routeplyline) }

        
       markerroute = L.marker(position, {icon: new Icon({  
           iconUrl: `../../assets/types/type-${icon}.png` ,
           rotationOrigin: "center", 
           iconSize: [60, 60], 
           iconAnchor: [25, 60], 
       })}).addTo(mapref) 
        

       let popupcontent =  getPopupcontent(listindex)  
       markerroute.bindPopup(popupcontent,{offset:[0, -66],keepInView:true,autoPan:true}) 
       markerstatus = L.marker(position,{icon:  new DivIcon({
           className: `marker-status-divicon  ${ 
               listindex?.status===7? 'marker-move': 
               listindex?.status===23?'marker-stop': 
               listindex?.status===24?'marker-idle':'marker-offline'
           }`, 
           iconSize: [20, 20],
           iconAnchor: [5, 63],  
       })}).addTo(mapref)

       routedraw?.push(position)
       if(routedraw.length-1 < passRouteIndex){
           let sliceroute = passroute?.route.slice(0,passRouteIndex)
           routedraw = sliceroute
       }
       routeplyline = L.polyline(routedraw,{color: color}).addTo(mapref)
        
       markerroute.on("contextmenu",(e:any)=>{
           mapref?.removeLayer(markerroute)
           mapref?.removeLayer(markerstatus) 
       })
     
       markerstatus?.setRotationOrigin("center" )   
       if(listindex?.status === 7){
           lastHeading = Number(listindex?.heading);
           markerstatus?.setRotationAngle(Number(listindex?.heading))
       }else{
           markerstatus?.setRotationAngle(lastHeading)
       }
       mapref?.invalidateSize() 
       if(trackMarker){
           mapref?.setView(position)
       }
   } 

   const playingRoute=()=>{
        console.log("playindex ",playindex ," passroute ", passroute?.list.length ," acton ",action)
       
        if(playindex+1 >= passroute?.route.length-1){
            routedraw=[]
            console.log("playindex more route ")
            clearInterval(passrouteinterval) ; 
            dispatch(setPassRouteInterval(undefined))
            
            stopRoute({});
            if(routeplyline){ mapref?.removeLayer(routeplyline)   }  
        }else{ 
            // console.log("playindex ",playindex ," passroute ", passroute?.list.length ," acton ",action)
            eventlocate =  passroute?.route[playindex] 
            let list  = passroute?.list[playindex]
            console.log("playindex ",playindex ," list ", passroute?.list.length ," acton ",action)
            drawMarkerL(passroute?.deviceIcon,list ,eventlocate) 
            let rowevent = document.getElementById(passroute?._id+"-current-"+playindex)
            if(rowevent){ 
                rowevent.scrollIntoView();
            }
            if(trackMarker){
              mapref?.setView( passroute?.route[playindex])
            }
        }
        playindex++;  
        dispatch(setPassRouteIndex(playindex)) 
    }

    const playRoute=(e:any)=>{  
        dispatch(setPlayAction("playing"))  
        if(passroute?.list.length >5){ 
            if( action ==="pause" && playindex!= passRouteIndex){
              playindex =passRouteIndex 
            } 
    
            playinterval.current = setInterval( () => {
                playingRoute() 
            } ,speed.val) 
            dispatch(setPassRouteInterval(playinterval?.current ))  
        }
       } 
 
   const pauseRoute=async(e:any)=>{  
    console.log("pause  passRouteIndex ",passRouteIndex, " ",playindex)
    playindex  = passRouteIndex 
    dispatch(setPassRouteIndex(playindex))
    dispatch(setPlayAction("pause"))   
    clearInterval(playinterval?.current) ;   
   }

   const stopRoute=async(e:any)=>{
    console.log("playindex ",passRouteIndex)
    console.log("playinterval ",passrouteinterval)
    clearInterval(playinterval?.current) ;
    if(markerroute){  mapref?.removeLayer(markerroute) }
    if(markerstatus){  mapref?.removeLayer(markerstatus) }
    if(routeplyline){  mapref?.removeLayer(routeplyline) } 
        dispatch(setPlayAction("stop"))  
        // dispatch(setPassRouteIndex(0))
        // playindex=0;
        routedraw =[];  
        mapref?.removeLayer(routeplyline)  

        clearInterval(playinterval?.current) ; 
        let rowevent = document.getElementById(passroute?._id+"-current-"+playindex)
        if(rowevent){ 
            rowevent.scrollIntoView();
       }
}
    const closeDailogMap=(bool:any)=>{
        setDialogView(bool);
        if(!bool){
            setViewRoute(null)
          
        } 
    }
      
    return(
    <IonPage>
        <IonHeader color='transparent' className='ion-no-border'   > 
        <IonToolbar color='transparent' mode='ios' className='ion-no-padding'>  
            <NavBar />  
            <IonButtons slot="start"  className='ion-hide-md-up  '  >
                <IonButton size='small' onClick={()=>{history.goBack()}} > <IonIcon icon={chevronBackOutline} />  <IonText >Back</IonText> </IonButton>
            </IonButtons>
            <IonTitle style={{fontWeight:"bold",fontSize:"1.3em",marginLeft:"1rem" }} >{'Reports'}</IonTitle>
        </IonToolbar>
        </IonHeader> 
        <IonContent className="ion-padding" > 
            <div style={{width:"100%",padding:".5rem"}}  >
                 
            <ul  className='toolbar   '  >
                <li>
                <DropDownDeviceMultiple 
                  vehicles={vehicleCheck} setVehicle={(index:any, val:any)=>{ checkedVehicleOption(index , val)  }}  
                  optionsbackup={options}  
                /> 
                </li>
                <li style={{display:"flex",flexDirection:"row",justifyContent:"space-evenly"}}><IonButton size='small' fill='clear' color={"dark"}>เริ่มต้น</IonButton> 
                    <DateStartReport date={datestart} openDate={setOpenDate} bg={true} showtime={true}/> 
                    <IonButton size='small' fill='clear' color={"dark"}>ถึง</IonButton>  
                    <DateEndReport date={dateend} openDate={setOpenDate} bg={true}   showtime={true} />
                </li> 
                <li>
                    <IonButton mode="ios" size="small" onClick={()=>{serachDeviceRoutes()}} > 
                      {spinner && <IonSpinner name="circles" /> }&nbsp; 
                      <IonLabel>  ค้นหาเส้นทาง </IonLabel>
                    </IonButton>
                </li>
                {dChecked.length > 0 &&
                <li>
                    <IonLabel>{deviceRoute.length} / {dChecked.length}</IonLabel>
                </li> }
            </ul>


            <div style={{width:"100%"}} >
                <IonRow>   {
                    deviceRoute.map((dr:any, i:any)=>
                        <IonCol key={i} size={dChecked.length === 1 ?  "12" : dChecked.length === 2 ? "6" : dChecked.length <= 6 ?  "4" : "3"} >
                            <div style={{height: dChecked.length <= 3 ? "78vh" : "38vh" , position:"relative" }} >   
                                <RouteMap 
                                 route={dr.route} did={dr.device_id} 
                                 bound={dr.route} name={dr?.name} 
                                 closeWindow={()=>{closeWindow(i ,dr)}}
                                 preview={()=>{
                                    setViewRoute(dr);
                                    setDialogView(true);
                                    dispatch(setPassRoute(dr))}}
                                /> 
                            </div>
                        </IonCol>
                    )
                } 
                </IonRow>
             
            </div>
            </div> 

            
           {
            viewRoute &&
            <DialogRoute 
             show={dialogView} 
             setShow={(e:any)=>{closeDailogMap(e) }} 
             route={viewRoute?.route} 
             did={viewRoute?.device_id} 
             bound={viewRoute?.bound} 
             name={viewRoute?.name}  
             action={action}
             stopRoute={stopRoute}
             playRoute={playRoute}
             pauseRoute={pauseRoute}
             setMapref={(e:MapType)=> mapref =e}
             trackMarker={trackMarker}
             setTrackMarker={(e:any)=>{setTrackMarker(e)}}
             datestart={datestart}
             dateend={dateend} 
             setOpenDate={(e:any)=>setOpenDate(e)}
             openDate={openDate}
             setDateStart={(e:any)=>setDateStart(e)}
             setDateEnd={(e:any)=>setDateEnd(e)}
            /> } 
            <DateRangPopover 
                start={datestart} 
                setStart={(e:any)=>{setDateStart(e) }} 
                end={dateend} 
                setEnd={(e:any)=>{setDateEnd(e); setOpenDate(false)}} 
                openDate={setOpenDate} isOpen={openDate} 
                daily={false}  
            /> 
        </IonContent>
  </IonPage>
    )
}
export default GroupRouteHistory;

let markerroute:any  = null ;
let markerstatus:any  = null ;
let routeplyline:any  = null ;
interface Velocity{
    series: any 
    options: any
}
const DialogRoute=({show,setShow,route, name,action,stopRoute,playRoute,pauseRoute,setMapref,trackMarker,setTrackMarker,datestart,dateend 
    ,openDate,setOpenDate,setDateStart,setDateEnd }:any)=>{
    // const [datestart,setDateStart] = useState(moment().format("YYYY-MM-DDT00:00").toString())
    // const [dateend,setDateEnd] = useState(moment().format("YYYY-MM-DDT23:59").toString())  
    
    const [speedChoice ,setSpeedChoice] = useState(false)
    const [speed,setSpeed] = useState(speeds[0]) 
    // const playAction = action //useSelector(getPlayAction)
    const [lstAction , setLstAction] = useState("stop") 
    const passRouteIndex = useSelector(getPassRouteIndex) 
    const passroute:Route = useSelector(getPassRoute) 
    const [parkPoints , setParkPoints] = useState<any[]>([])  
    const [color,setColor] = useState("#134985")
    const dispatch = useDispatch()
    const [detail,showDetail] = useState(true)
    const [tabs,setTabs] = useState(0)
    const [behavior,setBehavior] = useState<any>()
    const [segBehav , setSegBehav] = useState(129)
    const [velocity , setVelocity] = useState<Velocity | null>(null)
  
    let mapref:MapType|any = null
    let playindex = 0  

    var el = document.querySelector("#area-speed")
    const genVelocity=async()=>{
        let optionupdate:any = {
            chart: 	 { id: "area-speed"	, type: 'area', background: 'transparent' ,height: 250},
            xaxis: 	 { type: 'datetime' , categories:[] , }, 
            stroke:  { curve: 'smooth'	, width: 1 },  
            colors: ['#134985', '#FD5F06','#134985'],
            yaxis: {
                labels: {
                    formatter: function (value:any) {
                      return value.toFixed(0);
                    }
                },
            },
            dataLabels: { enabled: false },
            markers: { size: 0, style: 'hollow',  }, 
            tooltip: {
                x: {  format: 'HH:mm dd/MMM/yy'   }
            },
           fill: {
                type: 'gradient',
                gradient: {
                  shadeIntensity: 1,
                  opacityFrom: 0.7,
                  opacityTo: 0.9,
                  stops: [0, 100]
                }
            },  
          }
                  
        let serie:any = {  data: [] , name: "Speed(KM/H)" , type:"area" } 
        let serieData:any[] = []
        await passroute?.list.map((e,index)=>{
            if(index > 0 && index < passroute?.list.length -1){
                const route = passroute?.list[index] 
                serieData  =[...serieData ,  [  new Date(moment(route.event_timestamp).add(7,"hour").format()) ,  route.speed   ] ]
                optionupdate.xaxis.categories[index] = new Date(moment(route.event_timestamp).add(7,"hour").format())
            }
        }) 
        // optionupdate.series = serie
        serieData.filter((e:any)=> e!= null) 
        optionupdate.series = [{data : serieData ,  name: "Speed(KM/H)" , type:"area"}]
        setVelocity({
            series:  {data : serieData } , 
            options: optionupdate
        }) 
        el = document.querySelector("#area-speed")
        console.log("optionupdate ",optionupdate)
        console.log("area-speed ",el)
        
        if(el){ 
            var chart = new ApexCharts(document.querySelector("#area-speed"), optionupdate);
            chart.render();
        }
    }
    
    const doParkPoints=async()=>{
        let pp:any = []
        await passroute?.list.map((e,index)=>{
            if(index > 0 && index < passroute?.list.length -1){
                const nxt = passroute?.list[index+1]
                const pre = passroute?.list[index-1]
               if(e.status === pre?.status  && e.status !== nxt?.status&& e.status === 23){
                    pp= [...pp , {...e , ...{eindex: index}}] 
               }
            }
        }) 
        setParkPoints(pp)
    }
    const searchBehavior=async()=>{
        let res = await getBehaviorDevice({
            deviceId: passroute?.deviceId ,
            start: new Date(datestart).getTime() ,
            end: new Date(dateend).getTime()
        })  
        setBehavior(res) 
    }
 
    useEffect(()=>{  
        setTimeout(()=>{
            mapref?.invalidateSize()
            if(mapref && action === "stop"){ 
                setTimeout(()=>{  
                    mapref?.fitBounds(passroute?.route,{padding: [100,100],})
                    doParkPoints()
                    searchBehavior()
                    setTimeout(()=>{    genVelocity()  },100)
                },500)
            }
        },1000)
        
        return(()=>{
            if(!show){
                if(mapref){ mapref.eachLayer(function (layer:any) { 
                    if(layer?._url === null || layer?._url === undefined){ 
                        mapref.removeLayer(layer);
                    }
                })
                } 
                dispatch(setPlayAction("stop"))  
                dispatch(setPassRoute(null))  
            }
        })
    },[mapref, action ,el])

    const slelectSpeed=async(sp:any)=>{
        await pauseRoute({}) 
        setTimeout(()=>{ 
            setSpeed(sp)
            setSpeedChoice(false)
            if(action === "playing"){
             playRoute(null)
            }else{
                stopRoute({})
            }
        },300)
    }

    const selectbehav=(b:any)=>{ 
        let listbrhav= passroute?.list.filter(e=> e.event_timestamp == b?.eventTime) 
        if(listbrhav.length > 0){
            let behavindex = passroute?.list.indexOf(listbrhav[0],0) 
            dispatch(setPassRouteIndex(behavindex))
        }
    }
    
    return(<IonModal id="modal-dialogroute" mode="ios" isOpen={show} onIonModalDidDismiss={()=>{setShow(false);dispatch(setPlayAction("stop"))  }} > <IonHeader className="header ion-no-border"> 
 
    </IonHeader> 
            <DateRangPopover 
                start={datestart} 
                setStart={(e:any)=>{setDateStart(e) }} 
                end={dateend} 
                setEnd={(e:any)=>{setDateEnd(e); setOpenDate(false)}} 
                openDate={setOpenDate} isOpen={openDate} 
                daily={false}  
            /> 
        <IonContent className="ion-padding" >
            <IonButton   fill="clear"  mode="ios"  style={{position:"absolute" ,right:"1rem",zIndex: 999,}} onClick={()=>setShow(false)} >
                <IonIcon icon={closeCircleOutline} />
            </IonButton> 
            <IonGrid>
                <IonRow> 
                    <IonCol size="12">
                        <div className="set-center-row " style={{justifyContent:"flex-start"}}  >
                           <IonCheckbox 
                             checked={trackMarker} 
                             onIonChange={(e)=>{setLstAction(action);pauseRoute({}) ;setTrackMarker(e.detail.checked); action === "playing" && playRoute({})}} labelPlacement="end"
                            >
                             <strong>{name}</strong>
                            </IonCheckbox>
                             
                            <div className="set-center-row " style={{width:"30rem", marginLeft:"1rem",padding:".3rem"}} >
                                <DateStartReport date={datestart} openDate={setOpenDate} bg={true} showtime={true}/> 
                                <IonButton size='small' fill='clear' color={"dark"}>ถึง</IonButton>  
                                <DateEndReport date={dateend} openDate={setOpenDate} bg={true}   showtime={true} /> 
                            </div> 
                        </div>
                        <div style={{height:"50vh"}} className="set-center" >
                        <MapPassroute 
                            cssClass={"ion-hide-md-down"}
                            route={route} 
                            playingid={route?._id} 
                            speed={speed} 
                            passroute={passroute}
                            setPlayIndex={((e:any)=>{playindex = e } )}
                            playindex={playindex} 
                            setMapRef={(ref:any)=>{ setMapref(ref); mapref = ref ;  }}
                            stopRoute={()=>{stopRoute(null)}}
                            setModalssave={(open:any,point:any,radius:any)=>{}}
                            color={color}
                        /> 
                        <div style={{position:"absolute", zIndex:9999 , bottom:"1rem",left:"1rem" ,borderRadius:"5px",background:"#FFF" }}>
                            <button  className="ion-boxshadow ion-radius" onClick={()=>{showDetail(!detail)}} style={{position:"relative",padding:"7px"}}>
                                <IonIcon icon={listOutline} style={{fontSize:"larger"}} color="primary" /> 
                                {!detail &&  <IonIcon icon={alertCircle} color="danger" style={{position:"absolute",right:"2px",top:"-.5rem"}}/>  }
                           </button>
                           {detail &&<div className="bg-light" style={{position:'absolute' ,bottom:"3rem",zIndex:999,width:"20rem",borderRadius:"5px" , fontSize:".8em",minHeight:"8rem",padding:"5px"}} >
                            <IonGrid>
                                <IonRow> 
                                    <IonCol size="12" style={{position:"relative"}} >
                                        <IonButton fill="clear" size="small" style={{position:"absolute",right:"5px",top:"0"}} onClick={()=>{showDetail(false)}} >  <IonIcon icon={closeCircleOutline} /> </IonButton> 

                                        <IonLabel><strong>Time:</strong> {passroute?.list[passRouteIndex]?.event_stamp} </IonLabel> <br/>
                                        <IonLabel><strong>address:</strong> {passroute?.list[passRouteIndex]?.address} </IonLabel> <br/>
                                        <IonLabel> <strong>speed: </strong> {passroute?.list[passRouteIndex]?.speed} {t("kmHUnit")}</IonLabel><br/>
                                        <IonLabel>driver: {passroute?.list[passRouteIndex]?.driver_id} {passroute?.list[passRouteIndex]?.driver_message} </IonLabel><br/>
                                   </IonCol>
                                </IonRow>
                           </IonGrid>
                           </div> }
                        </div>

                        <div  style={{position:"absolute", zIndex:9999 , bottom:"1rem",  borderRadius:"5px",background:"#FFF",width:"30rem" }} >    
                            <IonRow className="ion-justify-content-center" >
                             <IonCol size="10" className="ion-no-padding">
                                 <IonRange value={passRouteIndex} max={passroute?.route.length} 
                                 onIonChange={(e)=>{ dispatch(setPassRouteIndex(e.detail.value)) }} 
                                 onIonKnobMoveStart={()=>{setLstAction(action);pauseRoute({}) }}
                                 onIonKnobMoveEnd={(e)=>{console.log("knobMoveEnd ",e.detail.value);dispatch(setPassRouteIndex(e.detail.value));   }}
                                 ></IonRange>
                             </IonCol>
                             <IonCol size="11" className="ion-no-padding" style={{display:"flex",flexDirection:"row",justifyContent:"flex-start" }} >
                                 <IonButtons 
                                 onMouseLeave={()=>{setSpeedChoice(false)}} 
                                 onMouseOver={()=>{setSpeedChoice(true)}} 
                                 style={{backgroundColor:"#FFF" , borderRadius:"5px",marginRight:".5rem"}} > 
                                 {!speedChoice &&
                                     <IonButton  onClick={()=>{setSpeedChoice(!speedChoice)}} mode={"ios"}  color={"primary"} fill="clear" size="small" >
                                         <IonLabel style={{fontSize:"small"}}>
                                             {speed.name}
                                         </IonLabel>
                                     </IonButton>  }{
                                     speedChoice &&  <>
                                     {  speeds.map((e:any,index:any)=>
                                         <IonButton  onClick={()=>{slelectSpeed(e)}} key={index} mode={"ios"}  fill="clear" size="small" 
                                                 color={e.val === speed.val ?"primary":"dark"} 
                                             >
                                                 <IonLabel style={{fontSize:"small"}}>
                                                 {e.name}
                                             </IonLabel>
                                         </IonButton>  
                                     )  } 
                                     </>
                                 }  
                                 </IonButtons>
                             
                                 <IonButtons style={{backgroundColor:"#FFF" , borderRadius:"5px"}} >
                                     <IonButton mode={"ios"}  onClick={(e)=>{playRoute(e)}} color={"primary"} fill="clear" size="small"  disabled={action === "playing" ?true:false}>
                                         <IonIcon icon={play} />
                                     </IonButton>
                                     <IonButton mode={"ios"} onClick={(e)=>{pauseRoute(e)}} color={"primary"}  fill="clear"  size="small"  disabled={action === "pause" ?true:false}>
                                         <IonIcon icon={pause} />
                                     </IonButton>
                                     <IonButton mode={"ios"} onClick={(e)=>{stopRoute(e)}} color={"primary"}  fill="clear" size="small"   disabled={action === "stop" ?true:false}>
                                         <IonIcon icon={stop} />
                                     </IonButton>
                                 </IonButtons>
                             </IonCol>
                            </IonRow>  
                        </div>
                        </div> 
                         
                    </IonCol>
                    <IonCol size="12" >
                        <Tabs className={"home-screen-tabs"} style={{ height: "100%"}}  selectedIndex={tabs}  onSelect={(index) => setTabs(index)} >
                            <TabList>
                                <Tab> จุดทั้งหมด </Tab> 
                                <Tab> จุดจอด</Tab>
                                <Tab> พฤติกรรมการขับ</Tab>
                                <Tab> น้ำมัน</Tab>
                            </TabList>
                            <TabPanel className="ion-padding" > 
                                <div className="passroute-details-dialog"   > 
                                    <table>
                                        <tr className="bg-light-shade">
                                            <th  className="ion-text-center"  style={{width:"3%"}}>#</th>
                                            <th style={{width:"40%"}}>address</th>
                                            <th className="ion-text-center" style={{width:"15%"}}>event_stamp</th>
                                            <th className="ion-text-center"  style={{width:"10%"}}>latitude</th>
                                            <th className="ion-text-center"  style={{width:"10%"}}>longitude</th>
                                            <th className="ion-text-center" style={{width:"10%"}}>speed</th>
                                            <th className="ion-text-center" style={{width:"10%"}}>status</th>
                                            <th className="ion-text-center" style={{width:"10%"}}>driver</th>
                                        </tr>
                                        {
                                            passroute?.list.map((e,i)=>
                                             <tr 
                                                id={passroute?._id+"-current-"+i} key={i} 
                                                className={`${i%2 ==0 ? "bg-light" : "bg-light-tint"} ${passRouteIndex === i && "bg-current"}  `} 
                                                style={{fontSize:".8em",borderBottom:"1px solid #fdfdfd"}} 
                                                onClick={()=>{ dispatch(setPassRouteIndex(i)) }}
                                             > 
                                                <td className="ion-text-center" >{e?.id+1}</td>
                                                <td style={{padding:"3px 0 3px"}}>{e?.address}</td>
                                                <td className="ion-text-center" >{e?.event_stamp}</td>
                                                <td className="ion-text-center" >{e?.latitude}</td>
                                                <td className="ion-text-center" >{e?.longitude}</td>
                                                <td className="ion-text-center">{e?.speed}</td>
                                                <td className="ion-text-center">{e?.status}</td>
                                                <td className="ion-text-center">{e?.driver_id} {e?.driver_message}</td>
                                             </tr>
                                            )
                                        }
                                    </table>
                              
                                </div>
                            </TabPanel> 
                            <TabPanel  > 
                                <div className="passroute-details-dialog"   > 
                                    <table>
                                        <tr className="bg-light-shade">
                                            <th  className="ion-text-center"  style={{width:"3%"}}>#</th>
                                            <th style={{width:"40%"}}>address</th>
                                            <th className="ion-text-center" style={{width:"15%"}}>event_stamp</th>
                                            <th className="ion-text-center"  style={{width:"15%"}}>lat /lon</th> 
                                            <th className="ion-text-center" style={{width:"10%"}}>speed</th>
                                            <th className="ion-text-center" style={{width:"10%"}}>status</th>
                                            <th className="ion-text-center" style={{width:"15%"}}>status time</th>
                                        </tr>
                                        {
                                            parkPoints && parkPoints.map((e,i)=>
                                             <tr id={passroute?._id+"-current-"+i} key={i} 
                                                className={`${i%2 ==0 ? "bg-light" : "bg-light-tint"} `} 
                                                style={{fontSize:".8em",borderBottom:"1px solid #fdfdfd"}} 
                                                onClick={()=>{ dispatch(setPassRouteIndex(e?.eindex)) }}
                                             > 
                                                <td className="ion-text-center" >{e?.id+1}</td>
                                                <td style={{padding:"3px 0 3px"}}>{e?.address}</td>
                                                <td className="ion-text-center" >{e?.event_stamp}</td>
                                                <td className="ion-text-center" >{e?.latitude}/{e?.longitude}</td> 
                                                <td className="ion-text-center">{e?.speed}</td>
                                                <td className="ion-text-center">{e?.status}</td>
                                                <td className="ion-text-center">{e?.statusTime}</td>
                                             </tr>
                                            )
                                        }
                                    </table>
                                </div>
                            </TabPanel>
                            <TabPanel > 
                            <IonSegment value={segBehav} onIonChange={((e:any)=>{setSegBehav(e.detail.value!)})} style={{maxWidth:"40rem"}} >
                                    {
                                        behavior?.behaviorGrouped.map((b:any,i:any)=> 
                                        <IonSegmentButton key={i}  value={b.behaviorId}>
                                            <IonLabel>{b?.name} {b?.pointRemoved > 0 && <small> ( {b?.pointRemoved} )</small> }</IonLabel>
                                        </IonSegmentButton>
                                        ) 
                                    }
                                   </IonSegment> <br/>
                                <div className="passroute-details-dialog" style={{height:"24vh",overflow:"scroll",padding:".56rem"}} > 
                                   
                                   <table style={{width:"100%"}}>
                                     <tr className="bg-light-shade">
                                        <th className="ion-text-center"  style={{width:"5%"}} >No.</th>
                                        <th className="ion-text-center"  style={{width:"40%" }} >address </th>
                                        <th className="ion-text-center"  style={{width:"20%"}} >time </th>
                                        <th className="ion-text-center"  style={{width:"15%"}} >driver</th>
                                        <th className="ion-text-center"  style={{width:"20%"}} >Lat / Lon </th>
                                     </tr>
                                     {
                                        behavior?.behaviorGrouped.filter((e:any)=> e.behaviorId === segBehav)[0].event.map((b:any,index:any)=>
                                        <tr id={passroute?._id+"-current-"+index} key={index}  
                                        style={{fontSize:".8em",borderBottom:"1px solid #fdfdfd"}} 
                                        onClick={(e)=>{selectbehav(b); mapref?.flyTo([b?.lat ,b?.lon],16)}}
                                        >
                                            <td className="ion-text-center"> {index+1}</td>
                                            <td style={{padding:" 5px 3px 5px 3px"}}>{b?.address}</td>
                                            <td className="ion-text-center">{moment(b?.eventTime).format("HH:mm DD/MM")}</td>
                                            <td className="ion-text-center">
                                                {b?.driverId} {b?.driverName}
                                            </td>
                                            <td className="ion-text-center">{b?.lat} / {b?.lon}</td>

                                         </tr>
                                        )
                                     }
                                     
                                   </table>
                                </div>
                            </TabPanel>
                            <TabPanel> 
                                {/* <div id="area-speed" ></div> */}
                                {/* <IonLabel>{JSON.stringify(velocity?.series)}</IonLabel> */}
                            {velocity &&  <ReactApexChart options={velocity?.options} series={velocity?.options?.series}  height={250} type="area" /> }
                               {/* {velocity && <VelocityChart series={velocity?.series} option={velocity?.options} /> }  */}
                            </TabPanel>
                        </Tabs> 
                    </IonCol>
                </IonRow>
            </IonGrid>
        </IonContent>
    </IonModal>)
}

const VelocityChart=({series , option}:any)=>{ 
    let options:any = {
        chart: 	 { id: "area-speed"	, type: 'line', background: 'transparent'},
        xaxis: 	 { type: 'datetime' , categories:[''] , },
        fill: 	 { type:'solid'	}   ,
        stroke:  { curve: 'smooth'	, width: 1 },
        markers: { size: 1 , strokeWidth: 0, },
        tooltip: { shared: false,  x: {
            show: true,
            format: 'dd/MM HH:mm',
            formatter: undefined,
        }, },
        colors: ['#fce303', '#16b526','#134985'],
        yaxis: {
            labels: {
                formatter: function (value:any) {
                  return value.toFixed(0);
                }
            },
        }, 
    }
    
      useEffect(()=>{
        // options =  option  
        // options = {...options , series}
        // console.log("options ",options)
        const drawChart=async()=>{
            var element= document.querySelector("#area-speed") 
            if(!element?.innerHTML){  
                options = option
                options.series = [series]  
                console.log("options ",options)
                
                var chrt:any = new ApexCharts(element, options)
                console.log("drawChart chrt ",chrt)
                chrt.render()  
            }
        }
        drawChart()
      },[])

    return(  
    //   series?.data.length > 0 && <ReactApexChart options={option} series={series}  height={250} type="area" /> 
    <div id="area-speed">
        {/* <ReactApexChart options={option} series={series}  height={250} type="area" />   */}
    </div>
    )
}

const RouteMap=({route,bound,name , preview ,closeWindow , children}:any)=>{
    const [mapref , setMapRef] = useState<MapType|null>(null)
    const [routeline , setRouteline] = useState([])
    useEffect(()=>{ 
        if( bound && mapref && bound.length > 0){ 
            setRouteline(bound)
            mapref?.fitBounds(bound)
        }else{
            mapref?.fitWorld()
        } 
    },[mapref,bound,routeline])

    return(<> 
      <div className="set-center-row group-toolbar  bg-dark"  >
        <IonLabel color={"light"}> <small>{ name}</small> </IonLabel>
        <IonButtons> 
            <IonButton size="small" mode="ios" fill="clear" color={"light"} 
             onClick={()=>{preview()}}
            >
                <IonIcon icon={expand} />
            </IonButton>
            <IonButton 
             size="small" mode="ios" fill="clear" color={"light"} 
             onClick={()=>{ bound.length > 0 ?  mapref?.fitBounds(bound):mapref?.fitWorld(); mapref?.invalidateSize()}}
            >
                <IonIcon icon={eyeOutline} />
            </IonButton> &nbsp;
            <IonButton color={"light"} onClick={()=>{ closeWindow() }} >
                <IonIcon icon={closeCircle } />
            </IonButton>
        </IonButtons>
    </div>
    <MapContainer 
        id='multiple-vehicle-map'
        center={[ 0,0]}
        zoom={16}
        scrollWheelZoom={true}
        zoomControl={false} 
        style={{borderRadius:"10px",height:"95%" }}
        ref={async (comp)=>{  
            comp?.invalidateSize()   
            if(comp){
                setMapRef(comp)
            }
        }} 
    >    
        <LayersControlMap  /> 
        {routeline.length > 0 && <FeatureGroup>
            <Polyline positions={routeline} />
            <Marker position={routeline[0]}  icon={markerStart} />    
            {routeline.length > 1 && <Marker position={routeline[routeline.length-1]}  icon={markerStop} />    }
        </FeatureGroup>}  
       
    </MapContainer> 
    </>
    )
}

 